<template>
  <div v-if="type === 'card'">

    <el-tooltip
      :content="`${$t('helper_failedvalidation')}`"
       :open-delay="100"
        placement="top"
        popper-class="adjust-width"
        v-if="returnStatus(stats) === 'failed'"
    >
      <p-button type="danger" round >
        <i class="fa fa-times-circle"></i>
        {{ $t('failedvalidationtxt') }}
      </p-button>
      </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_pendingvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validating'"
    >
      <p-button type="danger" round >
        <i class="fa fa-spinner fa-spin"></i>
        {{ $t('pendingvalidationtxt') }}
      </p-button>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_failedvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'disconnected'"
    >
      <p-button type="danger" round >
        <i class="fa fa-plug"></i>
        {{ $truncate($t("disconnected")) }}
      </p-button>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_validated')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validated'"
    >
      <p-button type="success" round >
        <i class="fa fa-check-circle"></i>
        {{ $t("validated") }}
      </p-button>
    </el-tooltip>

    <el-tooltip
      :content="stats.strategy_name"
      :open-delay="100"
      placement="top"
      v-else-if="returnStatus(stats) === 'hasStrategy'"
    >
      <p-button type="info" round >
        <i class="nc-icon nc-sound-wave"></i>
        {{ $truncate(stats.strategy_name) }}
      </p-button>
      </el-tooltip>

    <el-tooltip
      :content="`${$t('mastertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type === 'master'"
    >
      <p-button type="warning" icon round >M</p-button>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('followertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type !== 'master'"
    >
      <p-button type="info" icon round >F</p-button>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_sharetxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type === 'master' && stats.strategy_public"
    >
      <p-button type="warning" icon round  @click.native="copyLink(stats.account_url)">
        <i class="fa fa-share-alt"></i>
      </p-button>
    </el-tooltip>
  </div>
  <div v-else>
    <el-tooltip
      :content="`${$t('helper_failedvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-if="returnStatus(stats) === 'failed'"
    >
      <span class="text-danger">
        <i class="fa fa-times-circle"></i>
        {{ $t("failedvalidationtxt") }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_pendingvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validating'"
    >
      <span class="text-danger">
        <i class="fa fa-spinner fa-spin"></i>
        {{ $t("pendingvalidationtxt") }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_pendingvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'pending'"
    >
      <span class="text-danger">
        {{ $t("pendingvalidationtxt") }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_failedvalidation')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'disconnected'"
    >
      <span class="text-danger">
        <i class="fa fa-plug"></i>
        {{ $truncate($t("disconnected"), 10) }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_validated')}`"
      :open-delay="100"
      placement="top"
      popper-class="adjust-width"
      v-else-if="returnStatus(stats) === 'validated'"
    >
      <span class="text-success">
        <i class="fa fa-check-circle"></i>
        {{ $t("validated") }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="stats.strategy_name"
      :open-delay="100"
      placement="top"
      v-else-if="returnStatus(stats) === 'hasStrategy'"
    >
      <span class="text-info">
        <i class="nc-icon nc-sound-wave"></i>
        {{ $truncate(stats.strategy_name, 10) }}
      </span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('mastertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type === 'master'"
    >
      <span class="p-badge badge-warning badge-pill">M</span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('followertxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type !== 'master'"
    >
      <span class="p-badge badge-info badge-pill">F</span>
    </el-tooltip>

    <el-tooltip
      :content="`${$t('helper_sharetxt')}`"
      :open-delay="100"
      placement="top"
      v-if="stats.strategy_id && stats.account_type === 'master' && stats.strategy_public"
    >
      <span class="p-badge badge-warning badge-pill ml-1" @click="copyLink(stats.account_url)">
        <i class="fa fa-share-alt"></i>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
import {Tooltip} from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: {
    stats:  {
      description: 'An a account'
    },
    type: {
      default: 'card',
      description: 'card ou table'
    }
  },
  data() {
    return {

    }
  },
  methods: {
    returnStatus(stats) {
      if (stats.strategy_id !== undefined) {
        if(stats.account_status === 'disconnected' && stats.showDisconnected) {
          return 'disconnected';
        }
        if (stats.account_type === 'master' && stats.strategy_followers_count > 0) {
          return 'hasStrategy';
        }
        if (stats.account_type !== 'master') {
          return 'hasStrategy';
        }
      }
      if (stats.account_status === 'failed') {
        return 'failed';
      }
      if (stats.account_status === 'validating' || stats.account_status === 'checkingip' || stats.unvalidated) {
        return 'validating';
      }
      if (stats.account_status === 'validated'){
        return 'validated';
      }
      if (stats.account_status === 'disconnected') {
        return 'disconnected';
      }
      return 'validating';
    },
    copyLink(url) {
      this.$emit('copy-link', url);
    },
  }
}
</script>

<style scoped>

</style>
