<template>
  <module
      ref="module"
      id="myAccounts"
      titleIcon="fa fa-th-large"
      :title="$t('myaccounts')"
      :use-default-list="false"
  >
    <div slot="toolbar-global" class="row" v-if="admin_usr">
      <div class="btn-group mr-1 col-md-12">
        <el-tooltip
            :content="$t('back')"
            :open-delay="100"
            placement="top"
            popper-class="adjust-width"
        >
          <p-button type="default" v-on:click="prevPage()">
            <i class="nc-icon nc-minimal-left"></i>
          </p-button>
        </el-tooltip>
      </div>
    </div>
    <div slot="toolbar-global" v-if="!isMobile()">
       <drop-down
          tag="li"
          position="right"
          direction="none"
      >
        <p-button
            slot="title"
            slot-scope="{ isOpen }"
            :aria-expanded="isOpen"
            class="dropdown-toggle no-caret mr-1"
            type="success"
        >
          {{ $t("action") }} <i class="nc-icon nc-minimal-down"></i>
        </p-button>

        <a
            class="dropdown-item"
            v-if="act.show"
            v-on:click="whichAction(`${act.prop}`)"
            v-for="act in drop.actions"
        >
          {{ act.label }}
        </a>
      </drop-down>
    </div>
    <div slot="toolbar-global" v-if="!isMobile()">
      <p-button
            type="info"
            class="mr-1"
            v-on:click="addAccount"
        >
          <i class="nc-icon nc-simple-add"></i>
          {{ $t("addaccount") }}
        </p-button>
    </div>
    <div slot="toolbar-global">
      <el-tooltip :content="$t('refreshAccounts')" placement="top">
        <p-button class="mr-1" type="default" :disabled="refreshAccountsBtn" v-on:click="refreshAccounts()">
          <i class="fa fa-refresh">
            <div class="cooldown"></div>
          </i>
        </p-button>
      </el-tooltip>
    </div>
    <div slot="toolbar-global" v-if="!isMobile()">
      <p-button type="default" @click="openDrawer">
        <i class="fa fa-search"></i>
      </p-button>
    </div>

    <div slot="global">
      <el-drawer
        :visible.sync="showDrawer"
        :title="$t('advanced_filter_txt')"
        size="20%">
        <div class="form-group">
          <div class="col-md-12">
                  <label>{{ $t("searchtxt") }}</label>
                  <fg-input
                      class="input-sm"
                      v-model="search_user"
                      addon-right-icon="nc-icon nc-zoom-split"
                      v-on:keyup="search_users"
                  >
                  </fg-input>
                </div>
          <div class="col-md-12">
              <label>{{ $t("sortby") }}</label>
              <select
                  class="form-control"
                  v-model="sort_by.column"
                  v-on:change="this.reorder"
              >
                <option
                    v-for="item in sort_by.columns"
                    :key="item.prop"
                    :value="item.prop"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          <div class="col-md-12">
          <label>{{ $t("orderbytxt") }}</label>
          <select
              class="form-control"
              v-model="sort_by.order"
              v-on:change="this.reorder"
          >
            <option value="asc">
              {{ $t("ascending") }}
            </option>
            <option value="desc">
              {{ $t("descending") }}
            </option>
          </select>
          </div>
         </div>
      </el-drawer>
      <marketplace-follow-strategy
          :openAccountInfo="accountInfo"
          @on-finish="onRefresh"
      ></marketplace-follow-strategy>

      <remove-accounts-from-strategy
          v-if="showRemoveAccFromStrategyModal"
          @on-finish="onRefresh"
      ></remove-accounts-from-strategy>

      <delete-multiple-accounts></delete-multiple-accounts>

      <force-revalidation></force-revalidation>
      <SyncAccountsWithStrategy/>
      <!-- Modal to notify user where to change setting -->
      <modal
          :show.sync="modal_notice"
          :required="true"
          headerClasses="justify-content-center"
      >
        <h4 slot="header" class="title title-up">{{ modal_title }}</h4>

        <div class="row">
          <div class="col-12">
            {{ modal_msg }}
          </div>
          <div v-if="link_modal" class="col-12" style="margin-top: 20px">
            <a class="clickable" v-on:click="cp_clipboard">
              {{ link_modal }}
            </a>
            <input
                id="clipit"
                type="text"
                v-model="clipboard"
                style="display: none"
            />
          </div>
        </div>

        <div class="row" v-if="instruction_img">
          <div class="col-12 text-center">
            <img src="/static/img/instruction02.png" style="width: 50%"/>
          </div>
        </div>

        <div class="row" v-if="instruction_img">
          <div class="col-12">
            {{ modal_msg_ask }}
          </div>
        </div>

        <template slot="footer" v-if="!link_modal">
          <p-button
              type="primary"
              @click.prevent="gotItModal()"
              link
              v-if="is_master"
          >
            {{ $t("gotit") }}
          </p-button>

          <p-button type="primary" @click.prevent="autoedit" link v-else>
            {{ $t("follow_instruction") }}
          </p-button>

          <p-button type="default" @click.prevent="modal_notice = false" v-if="instruction_img" link>
            {{ $t("doit_later") }}
          </p-button>
        </template>

        <template slot="footer" v-else>
          <div class="left-side">
            <p-button @click.prevent="checkitout" link>{{
                $t("checkit")
              }}
            </p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="success" @click.prevent="gotItModal()" link>
              {{ $t("gotit") }}
            </p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal to show and process NET data -->
      <modal :show.sync="show_net_modal" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("forcenetfix") }}
        </h4>

        <div class="row">
          <div class="col-6">
            <fg-input
                :label="`${$t('netdeposit')}`"
                readonly="true"
                v-model="acc.account_net"
            >
            </fg-input>
          </div>
          <div class="col-6">
            <fg-input
                :label="`${$t('netdepositbefore')}`"
                readonly="true"
                v-model="acc.account_net_before"
            >
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <fg-input
                :label="`${$t('newnetdepval')}`"
                type="text"
                v-model="some_new_value"
            >
            </fg-input>
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button type="success" v-on:click="sendToFixNet()" link>{{
                $t("confirm")
              }}
            </p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" v-on:click="show_net_modal = false" link>{{
                $t("cancel")
              }}
            </p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for New or Update Own Entry -->
      <update-credentials
          :is-show="modal_update_credentials"
          :new-account="new_account"
          :acc="acc"
          :broker-list="broker_list"
          :admin-usr="admin_usr"
          @add:multi="multiAddAccount"
          @open:account-page="openAccountPage"
          @validate="validate"
          @modal:close="closeModal"
      />
      <!-- End Modal -->

      <!-- Modal for Confirm Delete -->
      <modal
          :show.sync="modal_confirm"
          :required="true"
          headerClasses="justify-content-center"
      >
        <h4 slot="header" class="title title-up">{{ $t("delacctitle") }}</h4>
        <div
            :class="`alert-${modal_alert_type}`"
            class="alert alert-dismissible fade show"
            v-if="modal_alert"
        >
          <button
              type="button"
              aria-hidden="true"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="modal_alert = false"
          >
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span>{{ modal_alert_msg }}</span>
        </div>

        <div class="row">
          <div class="col-12">
            {{ $t("confirmdelacc") }}
          </div>
        </div>

        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modal_in_progress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda"></i>
          </div>
          <div class="left-side" v-if="modal_in_progress === false">
            <p-button @click.prevent="confirmDeletion" link>{{
                $t("deletevps")
              }}
            </p-button>
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button type="danger" v-on:click="closeModalConfirmAlert" link>{{
                $t("cancel")
              }}
            </p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for Confirm Removal -->
      <modal
          :show.sync="modal_confirm_strat"
          :required="true"
          headerClasses="justify-content-center"
      >
        <h4 slot="header" class="title title-up">{{ $t("removestrat") }}</h4>
        <div
            :class="`alert-${modal_alert_type}`"
            class="alert alert-dismissible fade show"
            v-if="modal_alert"
        >
          <button
              type="button"
              aria-hidden="true"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="modal_alert = false"
          >
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span>{{ modal_alert_msg }}</span>
        </div>

        <div class="row">
          <div class="col-12">
            {{ $t("confirmremstrat") }}
          </div>
        </div>

        <div class="row">&nbsp;</div>

        <div class="row" v-if="remM">
          <div class="alert alert-danger">
            <i class="fa fa-exclamation-triangle"></i>
            {{ $t("moreinforemove") }}.
          </div>
        </div>

        <div class="row" v-else>
          <div class="alert alert-danger">
            <i class="fa fa-exclamation-triangle"></i>
            {{ $t("moreinforemove_follower") }}.
          </div>
        </div>

        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modal_in_progress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda"></i>
          </div>
          <div class="left-side" v-if="modal_in_progress === false">
            <p-button
                @click.prevent="confirmDeletionStrat"
                link
                type="success"
            >{{ $t("yestxt") }}
            </p-button
            >
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button
                type="danger"
                v-on:click="closeModalConfirmAlertStrat"
                link
            >{{ $t("notxt") }}
            </p-button
            >
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for Adding Strategy -->
      <modal
          :show.sync="modal_choose_strat"
          :required="true"
          headerClasses="justify-content-center"
      >
        <h4 slot="header" v-if="featching_strategies || strategies.length > 0" class="title title-up">
          {{ $t("addstrategy") }}</h4>
        <h4 slot="header" v-else class="title title-up">{{ $t("createorfollow") }}</h4>

        <!-- still loading strategies-->
        <div v-if="featching_strategies">
          <div class="text-center">
            <h4>{{ $t('loadingmarketstrategies') }}</h4>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
        </div>

        <!-- has strategies -->
        <div v-else-if="strategies.length > 0">

          <div
              :class="`alert-${modal_alert_type}`"
              class="alert alert-dismissible fade show"
              v-if="modal_alert"
          >
            <button
                type="button"
                aria-hidden="true"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                v-on:click="modal_alert = false"
            >
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>

          <div class="row">
            <div class="col-12" v-if="!admin_usr">
              <el-select
                  class="select-default form-control"
                  v-model="rem_sid"
                  :placeholder="choose_strat"
                  style="margin-bottom: 10px; border: 0"
              >
                <el-option
                    class="select-default"
                    v-for="s in strategies"
                    :key="s.strat_id"
                    :label="s.strat_name"
                    :value="s.strat_id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-lg-10 mx-auto" v-else>
              <fg-input
                  v-model="acc_strat"
                  addon-left-icon="nc-icon nc-sound-wave"
                  type="text"
                  :placeholder="choose_strat"
                  v-on:keyup="searchStrats"
              ></fg-input>
              <ul
                  v-if="show_auto_resultsStrats"
                  class="auto-ul auto-ul-strategies"
              >
                <li
                    v-for="r in auto_results_strats"
                    v-on:click="pickedStrat(`${r.strat_id}`)"
                >
                  {{ r.strat_name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row" v-if="rem_sid">
            <div class="col-md-12">
              <copy-all-trades-confirmation/>
            </div>
            <div class="alert" :class="agree ? 'alert-success' : 'alert-info'">
              <div class="text-justify">
                <span v-html="$t('byfollowingterms')"></span>
                {{ $t("pricingterms") }}
              </div>
              <div class="text-center">
                <br/>
                <p-checkbox v-model="agree" class="label-check">
                  <b class="text-bolder">{{ $t("iagreetxt") }}</b>
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!-- doesn't have strategies -->
        <div v-else>
          <div class="row">
            <div class="col-md-12 text-center">
              <i class="fa fa-exclamation-triangle fa-huge text-danger"></i>
              <p>
                {{ $t("nostrategies") }}
              </p>
            </div>
          </div>
        </div>

        <!-- footer for loading modal -->
        <template slot="footer" v-if="featching_strategies">
          <p-button disabled link type="success">{{ $t("ok") }}</p-button>
        </template>

        <!-- footer for follow strategy -->
        <template slot="footer" v-else-if="strategies.length > 0">
          <div class="py-3 mx-auto" v-if="modal_in_progress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda"></i>
          </div>
          <div
              class="left-side"
              v-if="modal_in_progress === false"
              v-bind:disabled="!rem_sid"
          >
            <p-button @click.prevent="confirmAddStrat" link type="success">
              {{ $t("addstrategy") }}
            </p-button>
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button type="danger" v-on:click="closeModalConfirmStrat" link>
              {{ $t("cancel") }}
            </p-button>
          </div>
        </template>

        <!-- footer when has no strategy to follow -->
        <template slot="footer" v-else>
          <div class="left-side">
            <p-button v-on:click="createStrategyBtn(`${rem_aid}`)" link>{{ $t("create") }}</p-button>
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button v-on:click="followStrategy" link>{{
                $t("followtxt")
              }}
            </p-button>
          </div>
        </template>

      </modal>
      <!-- End Modal -->

      <!-- Modal for Bulk Param Changes -->
      <modal
          :show.sync="strat_drop_modal"
          :required="true"
          headerClasses="justify-content-center"
      >
        <h4 slot="header" class="title title-up" v-if="show_aram_change_modal">
          {{ $t("paramaterchangetxt") }}
        </h4>
        <h4
            slot="header"
            class="title title-up"
            v-if="show_follow_strategy_modal"
        >
          {{ $t("bulk_folstrat") }}
        </h4>
        <h4
            slot="header"
            class="title title-up"
            v-if="show_remove_follow_strategy_modal"
        >
          {{ $t("bulk_remstrat") }}
        </h4>
        <div
            :class="`alert-${modal_alert_type}`"
            class="alert alert-dismissible fade show"
            v-if="modal_alert"
        >
          <button
              type="button"
              aria-hidden="true"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="modal_alert = false"
          >
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span>{{ modal_alert_msg }}</span>
        </div>

        <div class="row">
          <div class="col-12">
            <el-select
                class="select-default form-control"
                v-model="strat_param_change"
                :placeholder="choose_strat"
                style="margin-bottom: 10px; border: 0"
                v-if="show_follow_strategy_modal"
            >
              <el-option
                  class="select-default"
                  v-for="s in strategies"
                  :key="s.strat_id"
                  :label="s.strat_name"
                  :value="s.strat_id"
              >
              </el-option>
            </el-select>

            <el-select
                class="select-default form-control"
                v-model="strat_param_change"
                :placeholder="choose_strat"
                style="margin-bottom: 10px; border: 0"
                v-else
            >
              <el-option
                  class="select-default"
                  v-for="s in strat_param_change_list"
                  :key="s.strat_id"
                  :label="s.strategy_name"
                  :value="s.strategy_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <template slot="footer">
          <div class="left-side" v-if="modal_in_progress === false">
            <p-button
                @click.prevent="proceedParamChange"
                link
                v-if="show_aram_change_modal"
            >{{ $t("next") }}
            </p-button
            >
            <p-button
                @click.prevent="proceedFollowStrategyMultiple(false)"
                link
                v-if="show_follow_strategy_modal"
            >{{ $t("next") }}
            </p-button
            >
            <p-button
                @click.prevent="proceedRemoveFollowStrategyMultiple"
                link
                v-if="show_remove_follow_strategy_modal"
            >{{ $t("next") }}
            </p-button
            >
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button type="danger" v-on:click="cancelModalAlert()" link>{{
                $t("cancel")
              }}
            </p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for Sync w/ Strategy -->
      <modal :show.sync="syncstratmodal" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ modal_title }}
        </h4>

        <div class="row">
          <div class="col-12">
            {{ modal_msg }}
          </div>
        </div>

        <template slot="footer">
          <div class="py-3 mx-auto" v-if="modal_in_progress">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda"></i>
          </div>
          <div class="left-side" v-if="modal_in_progress === false">
            <p-button @click.prevent="confirmSyncStrat" link>{{
                $t("okaytxt")
              }}
            </p-button>
          </div>
          <div class="divider" v-if="modal_in_progress === false"></div>
          <div class="right-side" v-if="modal_in_progress === false">
            <p-button type="danger" v-on:click="closeModal" link>{{
                $t("cancel")
              }}
            </p-button>
          </div>
        </template>

      </modal>
      <!-- End Modal -->

      <!-- No Accounts -->
      <div
          v-if="!fail && clonedAccounts.length === 0 && !in_progress"
          class="text-center"
      >
        <h2>{{ $t("noaccounts") }}</h2>
        <p-button type="info" v-on:click="addAccount" style="margin: 0">
          <i class="nc-icon nc-simple-add"></i>
          {{ $t("addaccountdescription") }}
        </p-button>
      </div>

      <!-- mobile -->
      <div
          v-if="!fail && !in_progress && clonedAccounts.length > 0 && isMobile()"
      >
        <div class="row">
          <div class="col">
            <fg-input
                class="input-sm"
                :placeholder="$t('searchtxt')"
                v-model="search_user"
                addon-right-icon="nc-icon nc-zoom-split"
                v-on:keyup="search_users"
            >
            </fg-input>
          </div>
          <div class="col-3">
            <p-button
                type="default"
                round
                link
                v-on:click="openFilters()"
                style="margin: auto"
            >
              <i class="fa fa-cogs fa-2x"></i>
            </p-button>
          </div>
        </div>
        <div
            class="content"
            v-if="show_filters"
            style="padding-top: 10px; padding-bottom: 10px"
        >
          <div class="row">
            <div class="col-sm-12" style="padding-top: 5px">
              <label>{{ $t("sortby") }}</label>
              <select
                  class="form-control"
                  v-model="sort_by.column"
                  v-on:change="this.reorder"
              >
                <option
                    v-for="item in sort_by.columns"
                    :key="item.prop"
                    :value="item.prop"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-12" style="padding-top: 5px">
              <label>{{ $t("orderbytxt") }}</label>
              <select
                  class="form-control"
                  v-model="sort_by.order"
                  v-on:change="this.reorder"
              >
                <option value="asc" class="select-info">
                  {{ $t("ascending") }}
                </option>
                <option value="desc">
                  {{ $t("descending") }}
                </option>
              </select>
            </div>
            <hr/>
            <div class="col-sm-12">
              <drop-down
                  tag="li"
                  position="right"
                  direction="none"
                  style="margin-top: 19px"
              >
                <p-button
                    slot-scope="{ isOpen }"
                    slot="title"
                    type="success"
                    class="dropdown-toggle no-caret col-md-12"
                    :aria-expanded="isOpen"
                >
                  {{ $t("action") }} <i class="nc-icon nc-minimal-down"></i>
                </p-button>

                <a
                    class="dropdown-item"
                    v-if="act.show"
                    v-on:click="whichAction(`${act.prop}`)"
                    v-for="act in drop.actions"
                >
                  {{ act.label }}
                </a>
              </drop-down>
            </div>

            <div class="col-sm-12">
              <p-button type="info" class="col-md-12" v-on:click="addAccount">
                <i class="nc-icon nc-simple-add"></i>
                {{ $t("addaccount") }}
              </p-button>
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="!fail && !in_progress && clonedAccounts.length > 0 && !isMobile()"
          class="row"
      >
        <div class="col-md-12 text-right">
            <span>{{ $t('view_options') }} </span>
            <el-radio-group v-model="display" size="small" fill="#66615B" text-color="#fffcf5"	>
              <el-radio-button label="card" type="default">
                <i class="fa fa-th"></i>
              </el-radio-button>
              <el-radio-button label="table" type="default">
                <i class="fa fa-list"></i>
              </el-radio-button>
            </el-radio-group>
        </div>
      </div>

      <div class="text-center" v-if="fail">
        <span><i class="fa fa-warning fa-huge"></i></span>
        <h5 class="text-center">
          {{ $t("ERROR_UNEXPECTED") }}
        </h5>
      </div>

      <!-- In progress -->
      <div v-if="!fail && in_progress" class="text-center">
        <h2>{{ in_progress_txt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda"></i>
      </div>

      <!--Stats cards-->
      <div v-if="!fail && !in_progress && display === 'card'">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in statsCards">
            <div class="card" :class="stats.account_premium ? 'drop-shadow' : ''">
              <div class="card-header">
                <div class="float-right gear-icon">
                  <!--<drop-down class="btn-group"  position="right" :hide-arrow="true">-->
                  <drop-down
                      tag="li"
                      position="right"
                      direction="none"
                      class="custom-drop"
                  >
                    <p-button
                        slot-scope="{ isOpen }"
                        slot="title"
                        type="default"
                        round
                        icon
                        class="dropdown-toggle no-caret"
                        :aria-expanded="isOpen"
                    >
                      <i class="nc-icon nc-settings-gear-65"></i>
                    </p-button>
                    <a
                        class="dropdown-item"
                        v-if="strategyCheck(stats.strategy_id) && stats.account_type === 'master'"
                        v-on:click="openAccount(`${stats.account_id}`)"
                    >
                      {{ $t("infoaccounttitle") }}
                    </a>
                    <a
                        class="dropdown-item"
                        v-on:click="createStrategy(stats.account_id)"
                        v-if="
                        stats.account_status === 'validated' &&
                        can_create_strat &&
                        strategyCheck(stats.strategy_id) === false &&
                        !stats.nofollower_failed_login
                      "
                    >
                      {{ $t("createstrategytxt") }}
                    </a>

                    <a
                        class="dropdown-item"
                        v-on:click="addStrategy(`${stats.account_id}`)"
                        v-if="
                        stats.account_status === 'validated' &&
                        !strategyCheck(stats.strategy_id) &&
                        !stats.nofollower_failed_login
                      "
                    >
                      {{ $t("addstrategy") }}
                    </a>

                    <a
                        class="dropdown-item"
                        v-on:click="accountInfo(`${stats.account_number}`)"
                        v-if="stats.add === undefined"
                    >
                      {{ $t("editaccounttxt") }}
                    </a>

                    <a
                        class="dropdown-item"
                        v-on:click="accountDelete(stats.account_number)"
                        v-if="
                        stats.add === undefined &&
                        !strategyCheck(stats.strategy_id)
                      "
                    >
                      {{ $t("delacctitle") }}
                    </a>

                    <el-tooltip
                        :content="helper_remove_acct"
                        :open-delay="100"
                        placement="top"
                        popper-class="adjust-width"
                        v-else
                    >
                      <span class="dropdown-item text-muted">
                        {{ $t("delacctitle") }}
                      </span>
                    </el-tooltip>

                    <a
                        class="dropdown-item"
                        v-on:click="editStrategy(
                          `${stats.account_id}`,
                          `${stats.strategy_id}`,
                          true
                        )"
                        v-if="
                        strategyCheck(stats.strategy_id) && stats.account_type === 'master'
                      "
                    >
                      {{ $t("strategyinfo") }}
                    </a>

                    <a
                        class="dropdown-item"
                        v-on:click="
                          bulkRemoveFollowers(
                            stats,
                            stats.strategy_followers_count,
                          )
                        "
                        v-if="
                        admin_usr &&
                        strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                        stats.strategy_followers_count > 0
                      "
                    >
                      {{ $t("bulk_remstrat") }}
                    </a>

                    <a
                        class="dropdown-item"
                        v-on:click="
                        removeStrategy(
                          `${stats.account_id}`,
                          `${stats.strategy_id}`,
                          true
                        )
                      "
                        v-if="
                          strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                          stats.strategy_followers_count === 0
                        "
                    >
                      {{ $t("removestrat") }}
                    </a>
                    <span
                        v-else-if="
                          strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                          stats.strategy_followers_count > 0
                        "
                        class="dropdown-item text-muted"
                    >
                        {{ $t("removestrat") }}
                    </span>
                    <a class="dropdown-item"
                       v-if="admin_usr"
                       v-on:click="forceRevalidationComponent(stats)">
                      {{ $t('force_revalidation') }}
                    </a>
                    <a
                        class="dropdown-item"
                        v-on:click="masteraccount(`${stats.strategy_id}`)"
                        v-if="
                        strategyCheck(stats.strategy_id) && stats.account_type !== 'master'
                      "
                    >
                      {{ $t("tradesonstrategytxt") }}
                    </a>
                    <a
                        class="dropdown-item"
                        v-on:click="
                        syncStrategy(
                            `${stats.account_id}`,
                          `${stats.strategy_id}`,
                          `${stats.mat_servers}`,
                        )"
                        v-if="
                        strategyCheck(stats.strategy_id) &&
                        stats.account_type !== 'master' &&
                        stats.mat_servers !== null &&
                        (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                      "
                    >
                      {{ $t("syncwithstrattxt") }}
                    </a>
                    <el-tooltip
                        :content="helper_cannot_sync_helper"
                        :open-delay="100"
                        placement="top"
                        popper-class="adjust-width"
                        v-else-if="(
                            stats.account_status === 'disconnected' ||
                            stats.reporting_status === $t('pendingsetup')
                        )"
                    >
                      <span
                        class="dropdown-item text-muted"
                      >
                          {{ $t("syncwithstrattxt") }}
                      </span>
                    </el-tooltip>
                    <a
                        class="dropdown-item"
                        v-on:click="
                        openParams(
                          `${stats.account_id}`,
                          `${stats.strategy_id}`,
                        )
                      "
                        v-if="
                        strategyCheck(stats.strategy_id) && stats.account_type !== 'master'
                      "
                    >
                      {{ $t("paramaterchangetxt") }}
                    </a>
                    <a
                        class="dropdown-item"
                        v-on:click="
                        removeStrategy(
                          `${stats.account_id}`,
                          `${stats.strategy_id}`,
                          false
                        )
                      "
                        v-if="
                        strategyCheck(stats.strategy_id) && stats.account_type !== 'master'
                      "
                    >
                      {{ $t("removestrat") }}
                    </a>
                  </drop-down>
                </div>
                <div
                    class="float-right status-icon"
                    v-if="stats.unvalidated &&
                    strategyCheck(stats.strategy_id)"
                >
                  <el-tooltip
                      :content="`${$t('helper_failedvalidation')}`"
                      :open-delay="100"
                      placement="top"
                  >
                    <i
                        class="fa fa-circle"
                        :class="`text-${stats.status_type}`"
                    ></i>
                  </el-tooltip>
                </div>

                <div
                    class="float-right status-icon"
                    v-if="stats.add === undefined && stats.reporting_status"
                    style="right: 5px"
                >
                  <el-tooltip
                      :content="stats.reporting_status"
                      :open-delay="100"
                      placement="top"
                      v-if="stats.add === undefined && stats.reporting_status"
                  >
                    <i
                        class="fa fa-hourglass-o"
                        v-if="stats.reporting_status === $t('pendingsetup')"
                    ></i>
                    <i class="fa fa-hourglass-start" v-else></i>
                  </el-tooltip>
                </div>

                <div class="broker-logo" v-if="stats.add === undefined">
                  <img
                      v-if="stats.broker_logo && stats.broker_logo !== 'null'"
                      :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                  />
                  <img v-else alt="Broker"/>
                </div>

                <h4
                    class="card-title"
                    :class="
                    stats.account_status === 'disconnected'
                      ? 'text-danger'
                      : ''
                  "
                    v-if="stats.add === undefined"
                >
                  <span v-if="hasCustomNickname(stats)">
                    {{ stats.account_number }}
                  </span>
                  <span v-else class="flex-truncate" style="display:inline-block; width:60%">
                    {{ stats.account_nickname }}
                  </span>
                </h4>

                <p class="category" v-if="!stats.add" style="margin-bottom: 0">
                  <small class="category bold" v-if="!hasCustomNickname(stats)">
                    {{ stats.account_number }}
                  </small>
                  <b>({{ stats.account_platform.toUpperCase() }})</b>
                  {{ stats.account_server }}
                </p>

                <div class="row" style="margin-left: auto;">
                  <div class="col-md-9">
                    <my-accounts-status :stats="stats" @copy-link="copyLink"/>
                  </div>
                  <div class="col-md-3" style="font-size: small;">
                      <el-tooltip :content="`${$t('performancefee')}`"
                          :open-delay="100"
                          placement="bottom"
                          popper-class="adjust-width"
                          v-if="stats.strategy_public"
                      >
                        <div v-if="stats.account_demo">
                          <i class="fa fa-money"></i>&nbsp;&nbsp;0%
                        </div>
                        <div v-else>
                          <i class="fa fa-money"></i>&nbsp;&nbsp;{{ stats.fees.toFixed(0) }}%
                        </div>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('fixedfee')}`"
                          :open-delay="100"
                          placement="bottom"
                          popper-class="adjust-width"
                          v-if="
                            stats.strategy_public &&
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master'
                          "
                      >
                        <div
                            v-if="
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) && stats.account_type !== 'master'
                            "
                        >
                          <i class="fa fa-credit-card"></i>&nbsp;&nbsp;${{ stats.fixedfee.toFixed(2) }}
                        </div>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('followerstxt')}`"
                          :open-delay="100"
                          placement="bottom"
                          popper-class="adjust-width"
                          v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.strategy_followers_count > 0 &&
                            stats.account_type === 'master'
                          "
                      >
                        <div>
                          <i class="fa fa-users"></i>&nbsp;&nbsp;{{ stats.strategy_followers_count }}x
                        </div>
                      </el-tooltip>
                  </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="stats.add" class="card-pricing">
                      <div
                          class="card-icon icon-success"
                          v-on:click="addAccount"
                          style="cursor: pointer"
                      >
                        <i class="nc-icon nc-simple-add"></i>
                      </div>
                      <h6 class="card-title">
                        {{ $t("addaccountdescription") }}
                      </h6>
                    </div>

                    <div class="table-responsive" v-if="!stats.add">
                      <table class="table">
                        <tbody>
                        <tr>
                          <td>{{ $t("balance") }}
                            <span v-if="stats.info_currency">({{ stats.info_currency }})</span>
                          </td>
                          <td
                              class="text-right text-success"
                              v-if="typeof stats.info_balance === 'number'"
                          >
                            {{ parseFloat(stats.info_balance).toFixed(2) }}
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("equity") }}
                            <span v-if="stats.info_currency">({{ stats.info_currency }})</span>
                          </td>
                          <td
                              class="text-right text-info"
                              v-if="typeof stats.info_equity === 'number'"
                          >
                            {{ parseFloat(stats.info_equity).toFixed(2) }}
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("leveragetxt") }}</td>
                          <td class="text-right" v-if="stats.info_leverage">
                            1 : {{ stats.info_leverage }}
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("margin_level_txt") }}</td>
                          <td
                              class="text-right"
                              v-if="stats.info_margin_level"
                          >
                              <span
                                  v-if="
                                  stats.info_equity && stats.info_margin
                                "
                              >
                                {{
                                  parseFloat(
                                      (stats.info_equity /
                                          stats.info_margin) *
                                      100,
                                  ).toFixed(2)
                                }}%
                              </span>
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("openpl") }}
                            <span v-if="stats.info_currency">({{ stats.info_currency }})</span>
                          </td>
                          <td class="text-right" v-if="stats.info_profit">
                              <span
                                  :class="
                                  stats.info_profit < 0
                                    ? 'text-danger'
                                    : 'text-success'
                                "
                              >
                                {{ parseFloat(stats.info_profit).toFixed(2) }}
                              </span>
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("lastupdate") }}</td>
                          <td
                              class="text-right"
                              v-if="stats.info_last_update"
                          >
                            {{ stats.info_last_update }}
                          </td>
                          <td class="text-right" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        <tr v-if="admin_usr">
                          <td
                              colspan="2"
                              v-if="
                                strategyCheck(stats.strategy_id) &&
                                stats.mat_servers
                              "
                          >
                            {{ stats.mat_servers.replaceAll(',', ', ') }}
                          </td>
                          <td colspan="2" v-else>
                            {{ $t("notavailable") }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <hr v-if="!stats.add"/>
              <div class="card-footer">
                <span v-if="stats.account_cent">&nbsp;</span>
                <badge type="info" v-if="stats.account_cent">cents</badge>
                <span v-if="stats.account_demo">&nbsp;</span>
                <badge type="info" v-if="stats.account_demo">demo</badge>
                <!-- Earn More, Premium, Sponsored -->
                <span v-if="stats.isSuperIB">&nbsp;</span>

                <span v-if="!stats.demo && !stats.isSuperIB && stats.brokerCashback_possible">&nbsp;</span>

                <span v-if="!stats.account_demo && issponsored(stats)">&nbsp;</span>
                <el-tooltip
                    :content="sponsored_tt"
                    :open-delay="100"
                    placement="top"
                    popper-class="adjust-width"
                >
                  <badge
                      type="success"
                      v-if="!stats.account_demo && issponsored(stats)"
                  >
                    {{ $t("sponsoredtxt") }}
                  </badge>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-if="!fail && !in_progress && display === 'table' && statsCards && statsCards.length > 0">
        <div>
          <div class="card-body">
            <tabs>
              <tab-pane :title="`${$t('infotxt')}`">
                <table
                    class="table table-bordered table-bordered table-condensed table-x"
                >
                  <thead style="color: silver !important">
                  <tr>
                    <th>{{ $t("account") }}</th>
                    <th class="text-right">{{ $t("balance") }}</th>
                    <th class="text-right">{{ $t("equity") }}</th>
                    <th class="text-right">{{ $t("leveragetxt") }}</th>
                    <th class="text-right">{{ $t("margin_level_txt") }}</th>
                    <th class="text-right">{{ $t("openpl") }}</th>
                    <th class="text-center">{{ $t("optionstxt") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="stats in statsCards">
                    <td>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="logo-broker">
                            <img
                                v-if="stats.broker_logo !== 'null' && stats.broker_logo !== ''"
                                :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                            />
                            <img v-else alt="Broker"/>
                          </div>
                          <span
                              style="margin-top: 5px"
                              v-if="stats.account_nickname === stats.account_number"
                          > {{ stats.account_number }}</span
                          >
                          <span style="margin-top: 5px" v-else-if="stats.account_nickname"
                          > {{ stats.account_nickname }} ({{ stats.account_number }})</span
                          >
                          <span style="margin-top: 5px" v-else
                          > {{ stats.account_number }}</span
                          >
                        </div>
                        <div class="col-md-6" style="margin-top: 5px">
                          <div
                              class="float-right status-icon"
                              style="padding-left: 20px;"
                              v-if="stats.add === undefined && stats.reporting_status"
                          >
                            <el-tooltip
                              :content="stats.reporting_status"
                              :open-delay="100"
                              placement="top"
                            >
                              <i
                                  class="fa fa-hourglass-o"
                                  v-if="stats.reporting_status === $t('pendingsetup')"
                              ></i>
                              <i class="fa fa-hourglass-start" v-else></i>
                            </el-tooltip>
                          </div>
                          <div
                              class="float-right status-icon"
                              v-if="stats.unvalidated &&
                              strategyCheck(stats.strategy_id)"
                            >
                              <el-tooltip
                                  :content="`${$t('helper_failedvalidation')}`"
                                  :open-delay="100"
                                  placement="top"
                              >
                                <i
                                    class="fa fa-circle"
                                    :class="`text-${stats.status_type}`"
                                ></i>
                              </el-tooltip>
                          </div>

                          <my-accounts-status :stats="stats" type="table" @copy-link="copyLink" />

                        </div>
                      </div>
                    </td>

                    <td class="text-right">
                        <span v-if="stats.info_balance">
                          {{ stats.info_currency }}
                        </span>
                      <span class="text-success" v-if="stats.info_balance">
                          {{ parseFloat(stats.info_balance).toFixed(2) }}
                        </span>
                    </td>

                    <td class="text-right">
                        <span v-if="stats.info_equity">
                          {{ stats.info_currency }}
                        </span>
                      <span class="text-success" v-if="stats.info_equity">
                          {{ parseFloat(stats.info_equity).toFixed(2) }}
                        </span>
                    </td>

                    <td class="text-right">
                        <span>
                          {{ stats.info_leverage }}
                        </span>
                    </td>

                    <td class="text-right">
                        <span
                            class="text-success"
                            v-if="stats.info_equity && stats.info_margin"
                        >
                          {{
                            parseFloat(
                                (stats.info_equity / stats.info_margin) * 100,
                            ).toFixed(2)
                          }}%
                        </span>
                    </td>

                    <td class="text-right">
                        <span
                            class="text-success"
                            v-if="stats.info_profit && stats.info_profit > 0"
                        >
                          {{ parseFloat(stats.info_profit).toFixed(2) }}
                        </span>
                      <span
                          class="text-danger"
                          v-if="stats.info_profit && stats.info_profit <= 0"
                      >
                          {{ parseFloat(stats.info_profit).toFixed(2) }}
                        </span>
                    </td>

                    <td class="text-center">
                      <drop-down
                          tag="li"
                          position="right"
                          direction="none"
                          class="custom-drop"
                      >
                        <p-button
                            slot-scope="{ isOpen }"
                            slot="title"
                            type="default"
                            round
                            icon
                            class="dropdown-toggle no-caret"
                            :aria-expanded="isOpen"
                        >
                          <i class="nc-icon nc-settings-gear-65"></i>
                        </p-button>

                        <a
                            class="dropdown-item"
                            v-on:click="copyLink(stats.account_url)"
                            v-if="
                              stats.account_url &&
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("helper_sharetxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                            v-on:click="openAccount(`${stats.account_id}`)"
                        >
                          {{ $t("infoaccounttitle") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="createStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              can_create_strat &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("createstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="addStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("addstrategy") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountInfo(`${stats.account_number}`)"
                            v-if="stats.add === undefined"
                        >
                          {{ $t("editaccounttxt") }}
                        </a>
                        <a class="dropdown-item"
                           v-if="admin_usr"
                           v-on:click="forceRevalidationComponent(stats)">
                          {{ $t('force_revalidation') }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountDelete(`${stats.account_number}`)"
                            v-if="stats.add === undefined && !strategyCheck(stats.strategy_id)"
                        >
                          {{ $t("delacctitle") }}
                        </a>

                        <el-tooltip
                            :content="helper_remove_acct"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else
                        >
                            <span class="dropdown-item text-muted">
                              {{ $t("delacctitle") }}
                            </span>
                        </el-tooltip>

                        <a
                            class="dropdown-item"
                            v-on:click="
                              editStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("strategyinfo") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master' &&
                              stats.strategy_followers_count === 0
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                              stats.strategy_followers_count > 0
                            "
                            class="dropdown-item text-muted"
                        >
                            {{ $t("removestrat") }}
                        </span>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              masteraccount(`${stats.strategy_id}`)
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("tradesonstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="
                            syncStrategy(
                                `${stats.account_id}`,
                              `${stats.strategy_id}`,
                              `${stats.mat_servers}`,
                            )"
                            v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master' &&
                            stats.mat_servers !== null &&
                            (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                          "
                        >
                          {{ $t("syncwithstrattxt") }}
                        </a>
                        <el-tooltip
                            :content="helper_cannot_sync_helper"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else-if="(
                                stats.account_status === 'disconnected' ||
                                stats.reporting_status === $t('pendingsetup')
                            )"
                        >
                          <span
                            class="dropdown-item text-muted"
                          >
                              {{ $t("syncwithstrattxt") }}
                          </span>
                        </el-tooltip>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              openParams(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("paramaterchangetxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                false,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                      </drop-down>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </tab-pane>

              <tab-pane :title="`${$t('detailstxt')}`">
                <table
                    class="table table-bordered table-bordered table-condensed table-x"
                >
                  <thead style="color: silver !important">
                  <tr>
                    <th>{{ $t('account') }}</th>
                    <th class="text-center">{{ $t('startdate') }}</th>
                    <th>{{ $t('type') }}</th>
                    <th>{{ $t('sponsoredtxt') }}</th>
                    <th class="text-center">{{ $t('lastupdate') }}</th>
                    <th class="text-center">{{ $t('optionstxt') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="stats in statsCards">
                    <td column="account">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="logo-broker">
                            <img
                                v-if="
                                  stats.broker_logo &&
                                  stats.broker_logo !== 'null'
                                "
                                :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                            />
                            <img v-else alt="Broker"/>
                          </div>
                          <span v-if="stats.account_nickname === stats.account_number">
                              {{ stats.account_number }}
                          </span>
                          <span v-else-if="stats.account_nickname">
                            {{ stats.account_nickname }} ({{ stats.account_number }})
                          </span>
                          <span v-else>
                            {{ stats.account_number }}
                          </span>
                        </div>
                        <div class="col-md-6" style="margin-top: 5px">
                          <div
                              class="float-right status-icon"
                              style="padding-left: 20px;"
                              v-if="stats.add === undefined && stats.reporting_status"
                          >
                            <el-tooltip
                              :content="stats.reporting_status"
                              :open-delay="100"
                              placement="top"
                            >
                              <i
                                  class="fa fa-hourglass-o"
                                  v-if="stats.reporting_status === $t('pendingsetup')"
                              ></i>
                              <i class="fa fa-hourglass-start" v-else></i>
                            </el-tooltip>
                          </div>
                          <div
                              class="float-right status-icon"
                              v-if="stats.unvalidated &&
                              strategyCheck(stats.strategy_id)"
                            >
                              <el-tooltip
                                  :content="`${$t('helper_failedvalidation')}`"
                                  :open-delay="100"
                                  placement="top"
                              >
                                <i
                                    class="fa fa-circle"
                                    :class="`text-${stats.status_type}`"
                                ></i>
                              </el-tooltip>
                          </div>

                          <my-accounts-status :stats="stats" type="table" @copy-link="copyLink" />

                        </div>
                      </div>
                    </td>

                    <td column="startdate" class="text-center">
                        <span
                            v-if="
                            !stats.strategy_created_dt &&
                            !stats.strategy_followed_dt
                          "
                        >
                          {{ stats.info_last_update }}
                        </span>
                      <span v-if="stats.strategy_created_dt">
                          {{ stats.strategy_created_dt }}
                        </span>
                      <span v-if="stats.strategy_followed_dt">
                          {{ stats.strategy_followed_dt }}
                        </span>
                    </td>

                    <td column="type">
                        <span
                            class="text-warning"
                            v-if="stats.account_platform === 'mt4'"
                        >
                          {{ stats.account_platform.toUpperCase() }}
                        </span>

                      <span
                          class="text-danger"
                          v-if="stats.account_platform === 'mt5'"
                      >
                          {{ stats.account_platform.toUpperCase() }}
                        </span>

                      <span class="text-info" v-if="stats.account_cent===1 && stats.account_demo===false">
                          <i class="fa fa-dollar"></i>
                          Cents
                        </span>
                      <span class="text-info" v-if="stats.account_demo===true && stats.account_cent===0">
                          <i class="fa fa-desktop"></i>
                          Demo
                        </span>
                      <span
                          class="text-warning"
                          v-if="stats.account_cent===0 && stats.account_demo===false"
                      >
                          <i class="fa fa-desktop"></i>
                          Real
                        </span>
                    </td>

                    <td column="sponsoredtxt">
                        <span
                            class="text-success"
                            v-if="!stats.account_demo && issponsored(stats)"
                        >
                          <i class="fa fa-check"></i>
                        </span>
                      <span
                          class="text-danger"
                          v-if="stats.account_demo || !issponsored(stats)"
                      >
                          <i class="fa fa-times"></i>
                        </span>
                    </td>


                    <td column="lastupdate" class="text-center">
                      {{ stats.info_last_update }}
                    </td>

                    <td class="text-center">
                      <drop-down
                          tag="li"
                          position="right"
                          direction="none"
                          class="custom-drop"
                      >
                        <p-button
                            slot-scope="{ isOpen }"
                            slot="title"
                            type="default"
                            round
                            icon
                            class="dropdown-toggle no-caret"
                            :aria-expanded="isOpen"
                        >
                          <i class="nc-icon nc-settings-gear-65"></i>
                        </p-button>

                        <a
                            class="dropdown-item"
                            v-on:click="copyLink(stats.account_url)"
                            v-if="
                              stats.strategy_url &&
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("helper_sharetxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                            v-on:click="openAccount(`${stats.account_id}`)"
                        >
                          {{ $t("infoaccounttitle") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="createStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              can_create_strat &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("createstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="addStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("addstrategy") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountInfo(`${stats.account_number}`)"
                            v-if="stats.add === undefined"
                        >
                          {{ $t("editaccounttxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountDelete(`${stats.account_number}`)"
                            v-if="stats.add === undefined && !strategyCheck(stats.strategy_id)"
                        >
                          {{ $t("delacctitle") }}
                        </a>

                        <el-tooltip
                            :content="helper_remove_acct"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else
                        >
                            <span class="dropdown-item text-muted">
                              {{ $t("delacctitle") }}
                            </span>
                        </el-tooltip>

                        <a
                            class="dropdown-item"
                            v-on:click="
                              editStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("strategyinfo") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master' &&
                              stats.strategy_followers_count === 0
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                              stats.strategy_followers_count > 0
                            "
                            class="dropdown-item text-muted"
                        >
                            {{ $t("removestrat") }}
                        </span>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              masteraccount(`${stats.strategy_id}`)
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("tradesonstrategytxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                            syncStrategy(
                                `${stats.account_id}`,
                              `${stats.strategy_id}`,
                              `${stats.mat_servers}`,
                            )"
                            v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master' &&
                            stats.mat_servers !== null &&
                            (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                          "
                        >
                          {{ $t("syncwithstrattxt") }}
                        </a>
                        <el-tooltip
                            :content="helper_cannot_sync_helper"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else-if="(
                                stats.account_status === 'disconnected' ||
                                stats.reporting_status === $t('pendingsetup')
                            )"
                        >
                          <span
                            class="dropdown-item text-muted"
                          >
                              {{ $t("syncwithstrattxt") }}
                          </span>
                        </el-tooltip>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              openParams(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("paramaterchangetxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                false,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                      </drop-down>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </tab-pane>

              <!-- error -->
              <tab-pane :title="`${$t('allocation')}`">
                <table
                    class="table table-bordered table-bordered table-condensed table-x"
                >
                  <thead style="color: silver !important">
                  <tr>
                    <th>{{ $t("account") }}</th>
                    <th class="text-right">{{ $t("balance") }}</th>
                    <th class="text-right">{{ $t("equity") }}</th>
                    <th>{{ $t("allocationmethodtxt") }}</th>
                    <th>{{ $t("maxslippage") }}</th>
                    <th class="text-center">{{ $t("optionstxt") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="stats in statsCards">
                    <td column="account">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="logo-broker">
                            <img
                                v-if="
                                  stats.broker_logo &&
                                  stats.broker_logo !== 'null'
                                "
                                :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                            />
                            <img v-else alt="Broker"/>
                          </div>
                          <span v-if="stats.account_nickname === stats.account_number">
                              {{ stats.account_number }}
                          </span>
                          <span v-else-if="stats.account_nickname">
                            {{ stats.account_nickname }} ({{ stats.account_number }})
                          </span>
                          <span v-else>
                            {{ stats.account_number }}
                          </span>
                        </div>
                        <div class="col-md-6" style="margin-top: 5px">
                          <div
                              class="float-right status-icon"
                              style="padding-left: 20px;"
                              v-if="stats.add === undefined && stats.reporting_status"
                          >
                            <el-tooltip
                              :content="stats.reporting_status"
                              :open-delay="100"
                              placement="top"
                            >
                              <i
                                  class="fa fa-hourglass-o"
                                  v-if="stats.reporting_status === $t('pendingsetup')"
                              ></i>
                              <i class="fa fa-hourglass-start" v-else></i>
                            </el-tooltip>
                          </div>
                          <div
                              class="float-right status-icon"
                              v-if="stats.unvalidated &&
                              strategyCheck(stats.strategy_id)"
                            >
                              <el-tooltip
                                  :content="`${$t('helper_failedvalidation')}`"
                                  :open-delay="100"
                                  placement="top"
                              >
                                <i
                                    class="fa fa-circle"
                                    :class="`text-${stats.status_type}`"
                                ></i>
                              </el-tooltip>
                          </div>

                          <my-accounts-status :stats="stats" type="table" @copy-link="copyLink" />

                        </div>
                      </div>
                    </td>

                    <td column="balance" class="text-right">
                        <span v-if="stats.info_balance">
                          {{ stats.info_currency }}
                        </span>
                      <span class="text-success" v-if="stats.info_balance">
                          {{ parseFloat(stats.info_balance).toFixed(2) }}
                        </span>
                    </td>

                    <td column="equity" class="text-right">
                        <span v-if="stats.info_equity">
                          {{ stats.info_currency }}
                        </span>
                      <span class="text-success" v-if="stats.info_equity">
                          {{ parseFloat(stats.info_equity).toFixed(2) }}
                        </span>
                    </td>

                    <td column="allocationmethod">

                      <div
                          v-if="
                            stats.follower_settings &&
                            stats.follower_settings.allocationMethod
                          "
                      >
                          <span
                              v-if="
                              stats.follower_settings.allocationMethod === 'eqRatio'
                            "
                          >
                            {{ $t("helper_eqratio") }} =
                            {{ stats.follower_settings.eqRatio }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.allocationMethod === 'balRatio'
                            "
                        >
                            {{ $t("helper_balratio") }} =
                            {{ stats.follower_settings.balRatio }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.allocationMethod === 'lotMultiplier'
                            "
                        >
                            {{ $t("helper_lotmultiplier") }} =
                            {{ stats.follower_settings.lotMultiplier }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.allocationMethod === 'lotMoneyRatio'
                            "
                        >
                            {{
                            $t("allocationmethodlotmoneyxy", [
                              stats.follower_settings.lotMoneyRatioX,
                              stats.follower_settings.lotMoneyRatioY,
                            ])
                          }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.allocationMethod ===
                              'fixedLots'
                            "
                        >
                            {{ $t("helper_fixedlots") }} =
                            {{ stats.follower_settings.fixedLots }}
                          </span>
                      </div>
                    </td>

                    <td column="maxslippage">
                      <div v-if="stats.follower_settings">
                          <span
                              class="text-success"
                              v-if="
                              stats.follower_settings
                                .blockSignalsWithPriceDeviation &&
                              stats.follower_settings.maxPip
                            "
                          >
                            {{
                              parseFloat(stats.follower_settings.maxPip).toFixed(1)
                            }}
                          </span>
                        <span
                            v-else-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                            {{ $t("notxt") }}
                          </span>
                      </div>
                    </td>

                    <td class="text-center">
                      <drop-down
                          tag="li"
                          position="right"
                          direction="none"
                          class="custom-drop"
                      >
                        <p-button
                            slot-scope="{ isOpen }"
                            slot="title"
                            type="default"
                            round
                            icon
                            class="dropdown-toggle no-caret"
                            :aria-expanded="isOpen"
                        >
                          <i class="nc-icon nc-settings-gear-65"></i>
                        </p-button>

                        <a
                            class="dropdown-item"
                            v-on:click="copyLink(stats.account_url)"
                            v-if="
                              stats.account_url &&
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("helper_sharetxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                            v-on:click="openAccount(`${stats.account_id}`)"
                        >
                          {{ $t("infoaccounttitle") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="createStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              can_create_strat &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("createstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="addStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("addstrategy") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountInfo(`${stats.account_number}`)"
                            v-if="stats.add === undefined"
                        >
                          {{ $t("editaccounttxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountDelete(`${stats.account_number}`)"
                            v-if="stats.add === undefined && !strategyCheck(stats.strategy_id)"
                        >
                          {{ $t("delacctitle") }}
                        </a>

                        <el-tooltip
                            :content="helper_remove_acct"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else
                        >
                            <span class="dropdown-item text-muted">
                              {{ $t("delacctitle") }}
                            </span>
                        </el-tooltip>

                        <a
                            class="dropdown-item"
                            v-on:click="
                              editStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("strategyinfo") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master' &&
                              stats.strategy_followers_count === 0
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                              stats.strategy_followers_count > 0
                            "
                            class="dropdown-item text-muted"
                        >
                            {{ $t("removestrat") }}
                        </span>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              masteraccount(`${stats.strategy_id}`)
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("tradesonstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="
                            syncStrategy(
                                `${stats.account_id}`,
                              `${stats.strategy_id}`,
                              `${stats.mat_servers}`,
                            )"
                            v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master' &&
                            stats.mat_servers !== null &&
                            (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                          "
                        >
                          {{ $t("syncwithstrattxt") }}
                        </a>
                        <el-tooltip
                            :content="helper_cannot_sync_helper"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else-if="(
                                stats.account_status === 'disconnected' ||
                                stats.reporting_status === $t('pendingsetup')
                            )"
                        >
                          <span
                            class="dropdown-item text-muted"
                          >
                              {{ $t("syncwithstrattxt") }}
                          </span>
                        </el-tooltip>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              openParams(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("paramaterchangetxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                false,
                              )
                            "
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                      </drop-down>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </tab-pane>

              <tab-pane :title="`${$t('signals')}`">
                <table
                    class="table table-bordered table-bordered table-condensed table-x"
                >
                  <thead style="color: silver !important">
                  <tr>
                    <th>{{ $t("account") }}</th>
                    <th>{{ $t("assetstofollow") }}</th>
                    <th>{{ $t("buyselltrades") }}</th>
                    <th>{{ $t("newtradesignals") }}</th>
                    <th>{{ $t("closetradesignals") }}</th>
                    <th class="text-center">{{ $t("optionstxt") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="stats in statsCards">
                    <td>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="logo-broker">
                            <img
                                v-if="
                                  stats.broker_logo &&
                                  stats.broker_logo !== 'null'
                                "
                                :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                            />
                            <img v-else alt="Broker"/>
                          </div>
                          <span v-if="stats.account_nickname === stats.account_number">
                              {{ stats.account_number }}
                          </span>
                          <span v-else-if="stats.account_nickname">
                            {{ stats.account_nickname }} ({{ stats.account_number }})
                          </span>
                          <span v-else>
                            {{ stats.account_number }}
                          </span>
                        </div>
                        <div class="col-md-6" style="margin-top: 5px">
                          <div
                              class="float-right status-icon"
                              style="padding-left: 20px;"
                              v-if="stats.add === undefined && stats.reporting_status"
                          >
                            <el-tooltip
                              :content="stats.reporting_status"
                              :open-delay="100"
                              placement="top"
                            >
                              <i
                                  class="fa fa-hourglass-o"
                                  v-if="stats.reporting_status === $t('pendingsetup')"
                              ></i>
                              <i class="fa fa-hourglass-start" v-else></i>
                            </el-tooltip>
                          </div>
                          <div
                              class="float-right status-icon"
                              v-if="stats.unvalidated &&
                              strategyCheck(stats.strategy_id)"
                            >
                              <el-tooltip
                                  :content="`${$t('helper_failedvalidation')}`"
                                  :open-delay="100"
                                  placement="top"
                              >
                                <i
                                    class="fa fa-circle"
                                    :class="`text-${stats.status_type}`"
                                ></i>
                              </el-tooltip>
                          </div>

                          <my-accounts-status :stats="stats" type="table" @copy-link="copyLink" />

                        </div>
                      </div>
                    </td>

                    <td>
                      <div v-if="stats.follower_settings">
                        <div
                            v-if="
                              stats.follower_settings.assetsPairsToReceiveSignals
                            "
                        >
                            <span
                                v-if="
                                stats.follower_settings
                                  .assetsPairsToReceiveSignals === 'all'
                              "
                            >
                              {{ $t("allassets") }}
                            </span>
                          <span
                              v-if="
                                stats.follower_settings.assetsPairsToReceiveSignals === 'onlyTheseAssetsPairsReceiveSignal'
                              "
                          >
                              {{
                              $t("onlyTheseAssetsPairsReceiveSignal", [
                                stats.follower_settings.onlyTheseAssetsPairsReceiveSignal,
                              ])
                            }}
                            </span>
                          <span
                              v-if="
                                stats.follower_settings.assetsPairsToReceiveSignals === 'allButTheseAssetsPairsReceiveSignal'
                              "
                          >
                              {{
                              $t("allButTheseAssetsPairsReceiveSignal", [
                                stats.follower_settings.allButTheseAssetsPairsReceiveSignal,
                              ])
                            }}
                            </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div v-if="stats.follower_settings">
                          <span
                              v-if="
                              stats.follower_settings.tradeSide &&
                              stats.follower_settings.tradeSide === 'all'
                            "
                          >
                            {{ $t("alltxt") }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.tradeSide &&
                              stats.follower_settings.tradeSide === 'onlyBuy'
                            "
                        >
                            {{ $t("only_buy_txt") }}
                          </span>
                        <span
                            v-if="
                              stats.follower_settings.tradeSide &&
                              stats.follower_settings.tradeSide === 'onlySell'
                            "
                        >
                            {{ $t("only_sell_txt") }}
                          </span>
                      </div>
                    </td>

                    <td>
                      <div v-if="stats.follower_settings">
                          <span
                              v-if="stats.follower_settings.receiveNewTradeSignals"
                          >
                            {{ $t("yestxt") }}
                          </span>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                            {{ $t("notxt") }}
                          </span>
                      </div>
                    </td>

                    <td>
                      <div v-if="stats.follower_settings">
                          <span
                              v-if="stats.follower_settings.receiveNewCloseSignals"
                          >
                            {{ $t("notxt") }}
                          </span>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                            {{ $t("yestxt") }}
                          </span>
                      </div>
                    </td>

                    <td class="text-center">
                      <drop-down
                          tag="li"
                          position="right"
                          direction="none"
                          class="custom-drop"
                      >
                        <p-button
                            slot-scope="{ isOpen }"
                            slot="title"
                            type="default"
                            round
                            icon
                            class="dropdown-toggle no-caret"
                            :aria-expanded="isOpen"
                        >
                          <i class="nc-icon nc-settings-gear-65"></i>
                        </p-button>

                        <a
                            class="dropdown-item"
                            v-on:click="copyLink(stats.account_url)"
                            v-if="
                              stats.account_url &&
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("helper_sharetxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                            v-on:click="openAccount(`${stats.account_id}`)"
                        >
                          {{ $t("infoaccounttitle") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="createStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              can_create_strat &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("createstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="addStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("addstrategy") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountInfo(`${stats.account_number}`)"
                            v-if="stats.add === undefined"
                        >
                          {{ $t("editaccounttxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountDelete(`${stats.account_number}`)"
                            v-if="stats.add === undefined && !strategyCheck(stats.strategy_id)"
                        >
                          {{ $t("delacctitle") }}
                        </a>

                        <el-tooltip
                            :content="helper_remove_acct"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else
                        >
                            <span class="dropdown-item text-muted">
                              {{ $t("delacctitle") }}
                            </span>
                        </el-tooltip>

                        <a
                            class="dropdown-item"
                            v-on:click="
                              editStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("strategyinfo") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master' &&
                              stats.strategy_followers_count === 0
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                              stats.strategy_followers_count > 0
                            "
                            class="dropdown-item text-muted"
                        >
                            {{ $t("removestrat") }}
                        </span>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              masteraccount(`${stats.strategy_id}`)
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("tradesonstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="
                            syncStrategy(
                                `${stats.account_id}`,
                              `${stats.strategy_id}`,
                              `${stats.mat_servers}`,
                            )"
                            v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master' &&
                            stats.mat_servers !== null &&
                            (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                          "
                        >
                          {{ $t("syncwithstrattxt") }}
                        </a>
                        <el-tooltip
                            :content="helper_cannot_sync_helper"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else-if="(
                                stats.account_status === 'disconnected' ||
                                stats.reporting_status === $t('pendingsetup')
                            )"
                        >
                          <span
                            class="dropdown-item text-muted"
                          >
                              {{ $t("syncwithstrattxt") }}
                          </span>
                        </el-tooltip>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              openParams(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("paramaterchangetxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                false,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                      </drop-down>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </tab-pane>

              <tab-pane :title="`${$t('risklimits')}`">
                <table
                    class="table table-bordered table-bordered table-condensed table-x"
                >
                  <thead style="color: silver !important">
                  <tr>
                    <th>{{ $t("account") }}</th>
                    <th class="text-right">{{ $t("numberoftrades") }}</th>
                    <th class="text-right">{{ $t("lotsize") }}</th>
                    <th class="text-right">{{ $t("percmarginused") }}</th>
                    <th class="text-right">{{ $t("drawdown") }}</th>
                    <th class="text-right">{{ $t("accountstoploss") }}</th>
                    <th class="text-center">{{ $t("optionstxt") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="stats in statsCards">
                    <td>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="logo-broker">
                            <img
                                v-if="
                                  stats.broker_logo &&
                                  stats.broker_logo !== 'null'
                                "
                                :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${stats.broker_logo}.png`"
                            />
                            <img v-else alt="Broker"/>
                          </div>
                          <span v-if="stats.account_nickname === stats.account_number">
                              {{ stats.account_number }}
                          </span>
                          <span v-else-if="stats.account_nickname">
                            {{ stats.account_nickname }} ({{ stats.account_number }})
                          </span>
                          <span v-else>
                            {{ stats.account_number }}
                          </span>
                        </div>
                        <div class="col-md-6" style="margin-top: 5px">
                          <div
                              class="float-right status-icon"
                              style="padding-left: 20px;"
                              v-if="stats.add === undefined && stats.reporting_status"
                          >
                            <el-tooltip
                              :content="stats.reporting_status"
                              :open-delay="100"
                              placement="top"
                            >
                              <i
                                  class="fa fa-hourglass-o"
                                  v-if="stats.reporting_status === $t('pendingsetup')"
                              ></i>
                              <i class="fa fa-hourglass-start" v-else></i>
                            </el-tooltip>
                          </div>
                          <div
                              class="float-right status-icon"
                              v-if="stats.unvalidated &&
                              strategyCheck(stats.strategy_id)"
                            >
                              <el-tooltip
                                  :content="`${$t('helper_failedvalidation')}`"
                                  :open-delay="100"
                                  placement="top"
                              >
                                <i
                                    class="fa fa-circle"
                                    :class="`text-${stats.status_type}`"
                                ></i>
                              </el-tooltip>
                          </div>

                          <my-accounts-status :stats="stats" type="table" @copy-link="copyLink" />

                        </div>
                      </div>
                    </td>

                    <td class="text-right">
                        <span v-if="stats.follower_settings">
                          {{ stats.follower_settings.maxNumberOpenPositions || "" }}
                        </span>
                    </td>

                    <td class="text-right">
                        <span
                            v-if="
                            stats.follower_settings && stats.follower_settings.maxLotSize
                          "
                        >
                          {{
                            parseFloat(stats.follower_settings.maxLotSize).toFixed(2)
                          }}
                          {{ $t("lots") }}
                        </span>
                    </td>

                    <td class="text-right">
                        <span
                            v-if="
                            stats.follower_settings &&
                            stats.follower_settings.maxAccountMarginUsed
                          "
                        >
                          {{ stats.follower_settings.maxAccountMarginUsed }} %
                        </span>
                    </td>

                    <td class="text-right">
                        <span
                            v-if="
                            stats.follower_settings &&
                            stats.follower_settings.noNewTradesDrawdownAbove
                          "
                        >
                          {{ stats.follower_settings.noNewTradesDrawdownAbove }} %
                        </span>
                    </td>

                    <td class="text-right">
                      <div v-if="stats.follower_settings">
                          <span
                              v-if="
                              !stats.follower_settings.closeAllTradesAtOverallLoss
                            "
                          >
                            <span
                                v-if="strategyCheck(stats.strategy_id) &&
                                stats.account_type !== 'master'
                              "
                            >
                              {{ $t("disabled") }}
                            </span>
                          </span>
                        <span v-else>
                            <span
                                v-if="
                                stats.follower_settings
                                  .closeAllTradesAtOverallLossChoice ===
                                  'lossMoneyToCloseProfit' &&
                                stats.follower_settings.lossMoneyToCloseProfit
                              "
                            >
                              $ {{ stats.follower_settings.lossMoneyToCloseProfit }}
                            </span>
                            <span
                                v-if="
                                stats.follower_settings
                                  .closeAllTradesAtOverallLossChoice ===
                                  'lossPercentToCloseProfit' &&
                                stats.follower_settings.lossPercentToCloseProfit
                              "
                            >
                              {{ stats.follower_settings.lossPercentToCloseProfit }}
                              %
                            </span>
                          </span>
                      </div>
                    </td>

                    <td class="text-center">
                      <drop-down
                          tag="li"
                          position="right"
                          direction="none"
                          class="custom-drop"
                      >
                        <p-button
                            slot-scope="{ isOpen }"
                            slot="title"
                            type="default"
                            round
                            icon
                            class="dropdown-toggle no-caret"
                            :aria-expanded="isOpen"
                        >
                          <i class="nc-icon nc-settings-gear-65"></i>
                        </p-button>

                        <a
                            class="dropdown-item"
                            v-on:click="copyLink(stats.account_url)"
                            v-if="
                              stats.account_url &&
                              stats.strategy_public &&
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("helper_sharetxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-if="strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                            v-on:click="openAccount(`${stats.account_id}`)"
                        >
                          {{ $t("infoaccounttitle") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="createStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              can_create_strat &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("createstrategytxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="addStrategy(`${stats.account_id}`)"
                            v-if="
                              stats.account_status === 'validated' &&
                              !strategyCheck(stats.strategy_id) &&
                              !stats.nofollower_failed_login
                            "
                        >
                          {{ $t("addstrategy") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountInfo(`${stats.account_number}`)"
                            v-if="stats.add === undefined"
                        >
                          {{ $t("editaccounttxt") }}
                        </a>

                        <a
                            class="dropdown-item"
                            v-on:click="accountDelete(`${stats.account_number}`)"
                            v-if="stats.add === undefined && !strategyCheck(stats.strategy_id)"
                        >
                          {{ $t("delacctitle") }}
                        </a>

                        <el-tooltip
                            :content="helper_remove_acct"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else
                        >
                            <span class="dropdown-item text-muted">
                              {{ $t("delacctitle") }}
                            </span>
                        </el-tooltip>

                        <a
                            class="dropdown-item"
                            v-on:click="
                              editStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master'
                            "
                        >
                          {{ $t("strategyinfo") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                true,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type === 'master' &&
                              stats.strategy_followers_count === 0
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                        <span
                            v-else-if="
                              strategyCheck(stats.strategy_id) && stats.account_type === 'master' &&
                              stats.strategy_followers_count > 0
                            "
                            class="dropdown-item text-muted"
                        >
                            {{ $t("removestrat") }}
                        </span>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              masteraccount(`${stats.strategy_id}`)
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("tradesonstrategytxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                            syncStrategy(
                                `${stats.account_id}`,
                              `${stats.strategy_id}`,
                              `${stats.mat_servers}`,
                            )"
                            v-if="
                            strategyCheck(stats.strategy_id) &&
                            stats.account_type !== 'master' &&
                            stats.mat_servers !== null &&
                            (stats.account_status !== 'disconnected' && stats.reporting_status !== $t('pendingsetup'))
                          "
                        >
                          {{ $t("syncwithstrattxt") }}
                        </a>
                        <el-tooltip
                            :content="helper_cannot_sync_helper"
                            :open-delay="100"
                            placement="top"
                            popper-class="adjust-width"
                            v-else-if="(
                                stats.account_status === 'disconnected' ||
                                stats.reporting_status === $t('pendingsetup')
                            )"
                        >
                          <span
                            class="dropdown-item text-muted"
                          >
                              {{ $t("syncwithstrattxt") }}
                          </span>
                        </el-tooltip>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              openParams(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("paramaterchangetxt") }}
                        </a>
                        <a
                            class="dropdown-item"
                            v-on:click="
                              removeStrategy(
                                `${stats.account_id}`,
                                `${stats.strategy_id}`,
                                false,
                              )
                            "
                            v-if="
                              strategyCheck(stats.strategy_id) &&
                              stats.account_type !== 'master'
                            "
                        >
                          {{ $t("removestrat") }}
                        </a>
                      </drop-down>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </tab-pane>

            </tabs>
          </div>
        </div>
      </div>

    </div>
  </module>
</template>
<script scoped>
import Vue from "vue";
import {Badge, Modal, TabPane, Tabs} from "src/components/UIComponents";
import {Option, Select, Tooltip, RadioGroup, RadioButton} from "element-ui";
import Constants from "src/assets/constants";
import swal from "sweetalert2";
import VueCookies from "vue-cookies";
import MarketplaceFollowStrategy from "src/components/Dashboard/Views/Dashboard/Strategies/MarketplaceFollowStrategy";
import RemoveAccountsFromStrategy from "src/components/Dashboard/Views/Dashboard/Accounts/RemoveAccountsFromStrategy";
import DeleteMultipleAccounts from "@/components/Dashboard/Views/Dashboard/Accounts/DeleteMultipleAccounts";
import {mapActions, mapState} from "vuex";
import ForceRevalidation from "@/components/Dashboard/Views/Dashboard/ChangeLogs/force-revalidation.vue";
import CopyAllTradesConfirmation from "@/components/Dashboard/Views/Dashboard/Accounts/CopyAllTradesConfirmation.vue";
import SyncAccountsWithStrategy from "@/components/Dashboard/Views/Dashboard/Accounts/SyncAccountsWithStrategy.vue";
import UpdateCredentials from "@/components/Dashboard/Views/Dashboard/Accounts/UpdateCredentialsV2.vue";
import MyAccountsStatus from "@/components/Dashboard/Views/Dashboard/Accounts/my-accounts-status.vue";

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem("user-info"));
const wlSettings = JSON.parse(localStorage.getItem("wlSettings"));

const sdisplay = VueCookies.get("accountsDisplay") || "card";
const timercountdown = 1500;
const WLIST_EXPIRY = 15;
const STRAT_EXPIRY = 15;
const ACC_EXPIRY = 3;
const MINIMUM_REQUIRED_TO_CREATE_STRATEGY = 250;

Vue.use(Select);
Vue.use(Option);
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(require("vue-moment"));

Array.prototype.exists = function (needle) {
  let r = new RegExp(needle, "gi");
  let rez = [];

  for (let i in this) {
    if (this[i].match(r) !== null) {
      rez.push(this[i]);
    }
  }
  return rez;
};

export default {
  components: {
    MyAccountsStatus,
    UpdateCredentials,
    CopyAllTradesConfirmation,
    ForceRevalidation,
    Modal,
    [Tooltip.name]: Tooltip,
    Badge,
    TabPane,
    Tabs,
    MarketplaceFollowStrategy,
    RemoveAccountsFromStrategy,
    DeleteMultipleAccounts,
    SyncAccountsWithStrategy,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      display: sdisplay,
      statsCards: [],
      broker_list: [],
      clonedAccounts: [],
      fail: false,
      in_progress: true,
      in_progress_txt: this.$t("loadingaccounts"),
      new_account: true,
      agree: false,
      admin_usr: false,
      open_account: rules.open_account,
      search_user: null,
      search_txt: this.$t("searchtxt"),
      other_broker: false,
      modal: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: "danger",
      modal_in_progress: false,
      sort_by: {
        column: "dt_reference",
        order: "asc",
        columns: [
          {
            prop: "dt_reference",
            label: this.$t("startdate"),
          },
          {
            prop: "account",
            label: this.$t("account"),
          },
          {
            prop: "strategy",
            label: this.$t("strategytxt"),
          },
          {
            prop: "balance",
            label: this.$t("balance"),
          },
          {
            prop: "equity",
            label: this.$t("equity"),
          },
        ],
      },
      acc: {
        other: null,
        account_id: null,
        account_password: null,
        account_cent: false,
        account_demo: false,
        account_userid: null,
        account_disabled: false,
        account_reporting_status: false,
        account_status: null,
        account_public: false,
        account_platform: undefined,
        account_nickname: "",
        account_net: null,
        account_net_before: null,
        account_broker: '',
        account_server: '',
      },
      some_new_value: null,
      u_eml: null,
      poll_accs: null,
      modal_confirm: false,
      modal_update_credentials: false,
      modal_title: this.$t("editfollower"),
      modal_msg: this.$t("instructionsetting02"),
      modal_msg_ask: this.$t("instructionsetting03"),
      instruction_img: true,
      show_auto_results: false,
      servers: [],
      show_auto_results_broker: false,
      auto_results_broker: [],
      helper_remove_acct: this.$t("helper_removeacct"),
      helper_performance_trades: this.$t("performancetrades_tooltip"),
      helper_remove_followers_need: this.$t("removefollowersneed"),
      helper_cannot_sync_helper: this.$t("synccannotbeprocessed"),
      helper_no_followers: this.$t("strategy_has_no_followers"),
      modal_confirm_strat: false,
      rem_sid: null,
      rem_aid: null,
      mat_server: null,
      strategies: [],
      modal_choose_strat: false,
      choose_strat: this.$t("choosestrat"),
      modal_notice: false,
      remM: false,
      partner_brokers: [],
      account_earn_more: [],
      modal_earn: false,
      atleastone: false,
      show_earn_more: false,
      show_earn_more_path: true,
      is_broker: false,
      block_new_brokers: false,
      sponsored_tt: this.$t("sponsoredtt"),
      show_param_change_btn: false,
      strat_param_change_list: [],
      show_aram_change_modal: false,
      strat_param_change: null,
      auto_results_strats: [],
      show_auto_resultsStrats: false,
      can_create_strat: true,
      acc_strat: null,
      drop: {
        action: null,
        actions: [
          {
            prop: "mult-add-acc",
            label: this.$t("multipleaccountadd"),
            show: true,
          },
          {
            prop: "mult-fol-strat",
            label: this.$t("bulk_folstrat"),
            show: true,
          },
          {
            prop: "mult-rem-strat",
            label: this.$t("bulk_remstrat"),
            show: rules.isAdmin,
          },
          {
            prop: "mult-rem-acc",
            label: this.$t("bulk_remaccount"),
            show: true,
          },
          {
            prop: "syncaccountswithstrategy",
            label: this.$t("syncaccountswithstrategy"),
            show: true,
          },
        ],
      },
      show_follow_strategy_modal: false,
      show_remove_follow_strategy_modal: false,
      strat_drop_modal: false,
      link_modal: false,
      show_net_modal: false,
      is_master: false,
      filter: {
        drop_open: false,
        sponsoredby: true,
        fullprice: true,
        validated: true,
        unvalidated: true,
        master: true,
        follower: true,
        unassigned: true,
      },
      hide_premium: false,
      show_filters: false,
      other_server: "",
      clipboard: "https://dash.hokocloud.com/",
      copy_trading_pricing: {
        demo_account: 0.35,
        live_account: 0.85,
        sponsored_account: 0.0,
      },
      refreshAccountsBtn: false,
      featching_strategies: false,
      syncstratmodal: false,
      showDrawer: false,
    };
  },
  computed: {
    constants() {
      return constants
    },
    ...mapState("deleteMultipleAccounts", ["showDeleteMultipleAccountModal"]),
    ...mapState("removeAccountsFromStrategy", [
      "showRemoveAccFromStrategyModal",
    ]),
    ...mapState("copy_all_trades_confirmation", ["copy_all_trades"]),
  },
  methods: {
    ...mapActions("marketplaceFollowStrategy", [
      "openAccModal",
      "setAccounts",
      "setStrategy",
      "openSyncAccounts",
    ]),
    ...mapActions("removeAccountsFromStrategy", [
      "openRemoveAccounstFromStrategyModal",
      "setAccountsAvailableToRemove",
      "setStrategyToRemoveAccounts",
    ]),
    ...mapActions("deleteMultipleAccounts", [
      "openDeleteMultipleAccountsModal",
      "setAccountsToDelete",
    ]),
    ...mapActions('forceRevalidation', [
      'openForceRevalidationModal',
      'setAccount'
    ]),
    isMobile() {
      return Constants.methods.isMobile()
    },
    applyFilter(not_from_drop) {
      let _copiedAccs = this.statsCards.slice(0);

      if (this.statsCards.length === 0 || !this.search_user) {
        _copiedAccs = this.clonedAccounts.slice(0);
      }

      // Default, return all
      if (
          this.filter.sponsoredby &&
          this.filter.fullprice &&
          this.filter.validated &&
          this.filter.unvalidated &&
          this.filter.account_status &&
          this.filter.master &&
          this.filter.follower &&
          this.filter.unassigned
      ) {
        this.statsCards = _copiedAccs;
      } else {
        // Sponsored
        let filteredsponsored = [];
        if (this.filter.sponsoredby) {
          filteredsponsored = _copiedAccs.filter((o) => this.issponsored(o));
        }
        // Full Price
        let filteredfull = [];
        if (this.filter.fullprice) {
          filteredfull = _copiedAccs.filter((o) => !this.issponsored(o));
        }
        // Sponsored & Full Price
        let filtered = filteredsponsored.concat(filteredfull);
        // If empty, defaults to original
        if (filtered.length === 0) {
          filtered = _copiedAccs;
        }
        // Invalid
        let filteredinval = [];
        if (this.filter.unvalidated) {
          filteredinval = filtered.filter(o => {
            return !!(
                this.strategyCheck(o.strategy_id) &&
                o.account_status === "disconnected" ||
                o.account_status === "failed" ||
                o.nofollower_failed_login
            );
          });
        }
        // Validated
        let filteredval = [];
        if (this.filter.validated) {
          filteredval = filtered.filter(o => {
            return !(
                this.strategyCheck(o.strategy_id) &&
                o.account_status === "disconnected" ||
                o.account_status === "failed" ||
                o.nofollower_failed_login
            );
          });
        }

        // Invalid & Validated
        let filteredinvNval = filteredinval.concat(filteredval);

        // If empty, defaults to original
        if (
            filteredinvNval.length === 0 &&
            !this.filter.unvalidated &&
            !this.filter.validated
        ) {
          filteredinvNval = filtered;
        }
        // Master
        let filteredmaster = [];
        if (this.filter.master) {
          filteredmaster = filteredinvNval.filter((o) => {
            return this.strategyCheck(o.strategy_id) && o.account_type === "master";
          });
        }

        // Follower
        let filteredfol = [];
        if (this.filter.follower) {
          filteredfol = filteredinvNval.filter((o) => {
            return this.strategyCheck(o.strategy_id) && o.account_type !== "master";
          });
        }
        // Unassigned
        let filteredunassign = [];
        if (this.filter.unassigned) {
          filteredunassign = filteredinvNval.filter(o => !this.strategyCheck(o.strategy_id));
        }

        let filteredmfu = filteredmaster.concat(filteredfol);

        filteredmfu = filteredmfu.concat(filteredunassign);

        // If empty, defaults to original
        if (
            filteredmfu.length === 0 &&
            !this.filter.master &&
            !this.filter.follower &&
            !this.filter.unassigned
        ) {
          filteredmfu = filteredinvNval;
        }

        this.statsCards = filteredmfu;
      }

      if (not_from_drop === undefined || not_from_drop === null) {
        this.$refs["filterby_drop"].toggleDropDown();
      }
    },
    clearFilter() {
      this.filter = {
        sponsoredby: true,
        fullprice: true,
        validated: true,
        unvalidated: true,
        master: true,
        follower: true,
        unassigned: true,
      };
    },
    filterClick(obj) {
      this.filter[obj] = !this.filter[obj];
    },
    toggleDrop(e) {
      this.$refs["filterby_drop"].toggleDropDown();
    },
    configFollowModal() {
      this.modal_title = this.$t("editfollower");
      this.modal_msg = this.$t("instructionsetting02");
      this.instruction_img = true;
    },
    whichAction(action) {
      this.drop.action = action;
      if (this.drop.action === "mult-add-acc") {
        this.multiAddAccount();
      }
      if (this.drop.action === "mult-fol-strat") {
        this.followStrategyMultiple();
      }
      if (this.drop.action === "mult-strat-chg") {
        this.parameterChange();
      }
      if (this.drop.action === "mult-rem-strat") {
        this.removeFollowStrategyMultiple();
      }
      if (this.drop.action === "mult-rem-acc") {
        this.multiDelAccount();
      }
      if (this.drop.action === "syncaccountswithstrategy") {
        this.proceedSyncAccountsMultiple();
      }
    },
    multiDelAccount() {
      if (this.statsCards) {
        let account_list = this.statsCards.filter(
            (el) => el.status === "validating" || !this.strategyCheck(el.strat_id),
        );
        this.setAccountsToDelete(account_list);
        this.openDeleteMultipleAccountsModal();
      } else {
        this.$toast.warning(this.$t("noaccountstodelete"));
      }
    },
    multiAddAccount() {
      if (this.admin_usr) {
        this.$router.push("/admin_accounts/" + this.u_eml + "/bulkadd");
      } else {
        this.$router.push("bulkadd");
      }
    },
    masteraccount(sid) {
      if (this.admin_usr) {
        this.$router.push("/admin_accounts/historical/" + sid);
      } else {
        this.$router.push("/accounts/historical/" + sid);
      }
    },
    removeFollowStrategyMultiple() {
      if (this.strat_param_change_list.length > 0) {
        this.show_remove_follow_strategy_modal = true;
        this.strat_drop_modal = true;
      } else {
        this.$toast.warning(this.$t("noaccountsfound"));
      }
    },
    followStrategyMultiple() {
      this.show_follow_strategy_modal = true;
      this.strat_drop_modal = true;
    },
    parameterChange() {
      if (this.strat_param_change_list.length > 0) {
        this.show_aram_change_modal = true;
        this.strat_drop_modal = true;
      }
    },
    proceedRemoveFollowStrategyMultiple() {
      if (
          this.strat_param_change !== null &&
          this.strat_param_change !== undefined
      ) {
        let account_list = this.statsCards.filter(
            (el) =>
                el.account_type === "follower" &&
                el.strategy_id === this.strat_param_change,
        );

        let accounts_available = [];
        let stratInfo = this.strat_param_change_list.filter(
            (el) => el.strategy_id === this.strat_param_change,
        );
        this.setAccountsAvailableToRemove(account_list);
        this.setStrategyToRemoveAccounts(stratInfo[0]);
        this.openRemoveAccounstFromStrategyModal();
        this.show_remove_follow_strategy_modal = false;
        this.strat_drop_modal = false;
      }
    },
    proceedSyncAccountsMultiple() {

        let account_list = [];

        for (let i = 0; i < this.statsCards.length; i++) {
          if (
              !this.statsCards[i].nofollower_failed_login &&
              !this.statsCards[i].unvalidated
            ) {
              account_list.push(this.statsCards[i]);
            }
        }

        let accounts_to_sync = [];

        accounts_to_sync = account_list.filter((e) =>
            e.account_type === "follower" &&
            e.account_status !== "disconnected" &&
            e.reporting_status !== this.$t("pendingsetup") &&
            e.mat_servers !== null
        );
        this.setAccounts(accounts_to_sync);
        this.openSyncAccounts();
    },
    proceedFollowStrategyMultiple() {
      // localStorage.setItem('tmp_hc_strategies-fol', JSON.stringify(this.strategies));
      if (
          this.strat_param_change !== null &&
          this.strat_param_change !== undefined
      ) {
        let account_list = [];
        for (let i = 0; i < this.statsCards.length; i++) {
          if (
              !this.statsCards[i].nofollower_failed_login &&
              !this.statsCards[i].unvalidated
            ) {
              account_list.push(this.statsCards[i]);
            }
        }

        let accounts_available = [];

        account_list = account_list.filter((e) => !this.strategyCheck(e.strategy_id));
        account_list.filter((e) => accounts_available.push(e.account_id));
        this.setAccounts(accounts_available);
        this.openAccModal();
        this.setStrategy(this.strat_param_change);
        this.show_follow_strategy_modal = false;
        this.strat_drop_modal = false;
        this.strat_param_change = null;
      }
    },
    proceedParamChange() {
      if (
          this.strat_param_change !== null &&
          this.strat_param_change !== undefined
      ) {
        if (this.admin_usr) {
          this.$router.push(
              "/admin_strategies/" +
              this.u_eml +
              "/up/" +
              this.strat_param_change +
              "/bulk",
          );
        } else {
          this.$router.push(
              "/strategies/up/" + this.strat_param_change + "/bulk",
          );
        }
      }
    },
    cantFollowStrategy() {
      this.$toast.info(this.$t("WARN_STRATEGY_MINIMUM_BALANCE"));
    },
    addStrategy(aid) {
      this.modal_choose_strat = true;
      this.rem_sid = null;
      this.rem_aid = aid;
    },
    createStrategy(id) {
      if (id !== null && id !== undefined && id !== "null" && id !== "undefined") {
        if (this.hasMinimumBalanceToCreateStrategy(id)) {
          return this.$toast.warning(this.$t('minimal_ac_balance_to_create_strategies'))
        }
        if (this.admin_usr) {
          this.$router.push("/admin_strategies/" + this.u_eml + "/add/" + id);
        } else {
          this.$router.push("/strategies/add/" + id);
        }
      }
    },
    createStrategyBtn(id) {
      if (id !== null && id !== undefined && id !== "null" && id !== "undefined") {
        if (this.hasMinimumBalanceToCreateStrategy(id)) {
          return this.$toast.warning(this.$t('minimal_ac_balance_to_create_strategies'))
        } else {
          this.modal_choose_strat = false
          if (this.admin_usr) {
            this.$router.push("/admin_strategies/" + this.u_eml + "/add/" + id);
          } else {
            this.$router.push("/strategies/add/" + id);
          }
        }
      }
    },
    hasMinimumBalanceToCreateStrategy(id) {
      let account = this.statsCards.filter( el => el.account_id == id)
      let notValidNumber = isNaN(parseInt(account[0].info_balance))
      return true ? notValidNumber || parseInt(account[0].info_balance) < parseInt(MINIMUM_REQUIRED_TO_CREATE_STRATEGY) : false
      },
    followStrategy() {
      this.modal_choose_strat = false
      this.$router.push("/strategies/market");
    },
    autoedit() {
      this.modal_notice = false;
      this.$router.push(this.editStrategy(this.rem_aid, this.rem_sid, false));
    },
    openParams(aid, sid, master) {
      if (this.admin_usr) {
        this.$router.push("/admin_accounts/parameters/" + aid + "/" + sid);
      } else {
        this.$router.push("/accounts/parameters/" + aid + "/" + sid);
      }
    },
    editStrategy(aid, sid, master, old = false) {
      if (this.admin_usr) {
        if (master) {
          return this.$router.push("/admin_strategies/" + this.u_eml + "/" + aid + "/" + sid).catch();
        } else {
          if (old) {
            this.$router.push("/admin_strategies/" + this.u_eml + "/up/" + sid + "/" + aid);
          } else {
            this.$router.push("/admin_accounts/parameters/" + aid + "/" + sid);
          }
        }
      }
      else {
        if (master) {
          this.$router.push("/strategies/" + aid + "/" + sid);
        } else {
          if (old) {
            this.$router.push("/strategies/up/" + sid + "/" + aid);
          } else {
            this.$router.push("/accounts/parameters/" + aid + "/" + sid);
          }
        }
      }
    },
    bulkRemoveFollowers(strat, followers) {
      if (followers === 0) {
        return this.$toast.warning(this.$t("strategy_has_no_followers"));
      } else {
        let success = (resp) => {
          this.setAccountsAvailableToRemove(resp.data);
          this.setStrategyToRemoveAccounts(strat);
          this.openRemoveAccounstFromStrategyModal();
        };
        let failOP = (resp) => {
          swal(this.$t("attentiontxt"), this.$t("rdpdownmsg"), "warning");
        };

        this.getStrategyFollowersHokoAdmin(strat.strategy_id).then(
            success,
            failOP,
        );
      }
    },
    syncStrategy(aid, sid, mats) {
      this.mat_server = mats
      this.rem_aid = aid
      this.rem_sid = sid
      this.modal_title = this.$t("syncwithstrattxt")
      this.modal_msg = this.$t("syncwithstratmodaltxt")
      this.instruction_img = false
      this.syncstratmodal = true
    },
    confirmSyncStrat() {
      this.closeModal();
      this.$addToSyncTrades({
        data: [
            {
              strategy_id: this.rem_sid,
              account_id: this.rem_aid,
              mat_server: this.mat_server
            }
      ]
      }).then(this.syncStratSuccess, this.failop)
    },
    syncStratSuccess(resp) {
      this.rem_sid = null
      this.rem_aid = null
      if(resp.success) {
        this.$toast.success(this.$t(resp.msg));
      } else {
        this.$toast.error(this.$t(resp.msg));
      }
    },
    removeStrategy(aid, sid, master) {

      this.rem_sid = sid;
      this.rem_aid = aid;
      this.remM = false;
      this.configFollowModal();
      this.is_master = false;

      let morethanone = 0;
      if (master) {
        let account = this.clonedAccounts.find(el => el.account_id == aid)
        if (account && account.strategy_followers_count > 0) {
          this.modal_notice = true;
          this.is_master = true;
          this.modal_title = this.$t("deletestrat");
          this.modal_msg = this.$t("removefollowersneed");
          this.instruction_img = false;
        } else {
          this.remM = true;
          this.is_master = false;
          this.modal_confirm_strat = true;
        }
      } else {
        this.is_master = false;
        this.modal_confirm_strat = true;
      }
    },
    showVPSInfo(stats) {
      this.modalvpscreds = true;
    },
    closeModalConfirmStrat(showModalNotice) {
      this.modal_choose_strat = false;
      if (!showModalNotice) {
        this.rem_aid = null;
        this.rem_sid = null;
      }
      this.closeModal();
    },
    closeModalConfirmAlertStrat() {
      this.modal_confirm_strat = false;
      this.rem_aid = null;
      this.rem_sid = null;
      this.remM = false;
      this.closeModal();
    },
    closeModalConfirmAlert() {
      this.modal_confirm = false;
      this.closeModal();
    },
    closeModalEarn() {
      this.modal_alert = false;
      this.modal_earn = false;
      this.atleastone = false;
    },
    closeModal() {
      this.modal_update_credentials = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      this.modal_alert_type = "danger";
      this.acc = {
        account: null,
        server: null,
        password: null,
        cents: false,
        userid: null,
        disabled: false,
        status: null,
        broker: null,
        platform: undefined,
        nickname: "",
      };
      this.syncstratmodal = false;
      this.modal_in_progress = false;
      this.new_account = true;
      this.show_auto_results_broker = false;
      this.show_auto_results = false;
      this.auto_results_broker = [];
      // Reload Accounts
      this.getAccounts();
    },
    checkitout() {
      window.open(this.link_modal, "_blank");
      this.link_modal = false;
      this.modal_notice = false;
      this.configFollowModal();
    },
    copyLink(url) {
      this.clipboard = this.link_modal = this.getBaseUrl(url);
      this.modal_notice = true;
      this.modal_title = this.$t("copylinktxt");
      this.modal_msg = this.$t("followingisstrategylink");
      this.instruction_img = false;
    },
    cp_clipboard() {
      let cb = document.getElementById("clipit");
      cb.style.display = "block";
      cb.select();
      document.execCommand("copy");
      cb.style.display = "none";
      this.$toast.info("Copied");
    },
    getBaseUrl(url) {
      return rules.getPublicUrl(url);
    },
    _parseEarnMore(accInfo, openModal) {
      const bidx = this.partner_brokers.findIndex(
          (b) => b.name.toLowerCase() === accInfo.broker_name.toLowerCase(),
      );
      const bInfo = this.partner_brokers[bidx];
      if (!(accInfo.account in this.account_earn_more)) {
        this.account_earn_more.push({
          account: accInfo.account_number,
          cashback: bInfo.cashback,
          primaryeml: bInfo.primaryeml,
          secondary: bInfo.secondary,
          broker: bInfo.name,
          logo: bInfo.logo,
          link: bInfo.link,
          selected: true,
        });
      }
      // Open Modal
      if (openModal) {
        this.modal_earn = openModal;
      }
    },
    saveDisplay(display) {
      this.display = display;
      VueCookies.set(
          "accountsDisplay",
          display,
          null,
          null,
          null,
          false,
          'Lax',
      );
    },
    earnMore(aid) {
      let openModal = false;
      this.account_earn_more = [];

      if (aid) {
        const aidx = this.statsCards.findIndex((a) => a.account_id === aid);
        const accInfo = this.statsCards[aidx];
        this._parseEarnMore(accInfo, true);
        return;
      }

      for (var i = 0; i < this.statsCards.length; i++) {
        let accInfo = this.statsCards[i];
        if (!accInfo.account_premium && accInfo.broker_free_trade_available) {
          this._parseEarnMore(this.statsCards[i], false);
        }
      }
      if (i === this.statsCards.length) {
        this.account_earn_more.sort(function (a, b) {
          if (a.broker_name === b.broker_name) {
            return 0;
          }
          return a.broker_name > b.broker_name ? 1 : -1;
        });
        this.modal_earn = true;
      }
    },
    // sendEmailToBroker(idx) {
    //   const _sentEarnMore = (resp) => {
    //     this.sendEmailToBroker(idx + 1);
    //   };
    //
    //   if (idx < this.account_earn_more.length) {
    //     const em = this.account_earn_more[idx];
    //     if (em.selected) {
    //       let json_data = Object.assign({}, this.account_earn_more[idx]);
    //       json_data["userid"] = this.u_eml;
    //       json_data["name"] = user_data["user_metadata"]["first_name"];
    //       if (this.admin_usr) {
    //         json_data["name"] = this.u_eml;
    //       }
    //       this.atleastone = true;
    //       this.$sendEarnMore(json_data).then(_sentEarnMore, this.failop);
    //     } else {
    //       _sentEarnMore();
    //     }
    //   } else {
    //     this.modal_in_progress = false;
    //     if (!this.atleastone) {
    //       this.$toast.warning(this.$t("helper_allocationmethodtxt"));
    //     } else {
    //       swal(this.$t("infotxt"), this.$t("earnmoretxtsuccess"), "info");
    //       setTimeout(this.closeModalEarn, timercountdown * 1.5);
    //     }
    //   }
    // },
    openAccount(acc) {
      const baseRoute = this.admin_usr ? "admin_accounts" : "accounts";
      const location = `/${baseRoute}/${this.u_eml}/${acc}`;
      this.$router.push(location).catch(() => this.$router.push(location));
    },
    openAccountPage() {
      if (this.open_account) {
        window.open(this.open_account, "_blank");
        return;
      }
      if (this.admin_usr) {
        this.$router.push("/admin_accounts/open");
      } else {
        this.$router.push("/accounts/open");
      }
    },
    addAccount() {
      this.accountInfo("");
    },
    getStrategies() {
      this.featching_strategies = true
      // Get Accounts
      this.u_eml = this.admin_usr ? this.$route.params.userid : user_data.email;
      let allstrats = localStorage.getItem("hc_admin_all_strats-" + this.u_eml);
      let existLocal = false;
      const now = this.$moment("timezone", "Europe/London", "LLLL ss").unix();

      if (allstrats) {
        allstrats = JSON.parse(allstrats);
        if (now <= allstrats.expiry) {
          this.loadStrategies(allstrats.resp, true);
          return;
        }
      }
      if (this.admin_usr) {
        this.$getStrategiesOverview().then(this.loadStrategies, this.failop);
      } else {
        this.$getStrategies(this.u_eml).then(this.loadStrategies, this.failop);
      }
    },
    getAccounts() {
      // Get Accounts
      if (this.admin_usr || this.is_broker) {
        this.u_eml = this.$route.params.userid;
      } else {
        this.u_eml = user_data.email;
      }
      // Check Cache
      const now = this.$moment("timezone", "Europe/London", "LLLL ss").unix();
      let existlocal = false;
      let accounts = localStorage.getItem("hc_accounts-" + this.u_eml);
      if (accounts !== null && accounts !== undefined) {
        accounts = JSON.parse(accounts);
        if (now <= accounts.expiry) {
          existlocal = true;
        }
      }
      if (!existlocal) {
        this.$getAllAccounts_v2({eml: this.u_eml}).then(
            this.loadAccountsRaw,
            this.failop,
        );
      } else if (accounts) {
        this.statsCards = accounts.accounts;
        this.show_param_change_btn = accounts.show_param_change_btn;
        this._loadAccounts();
      }
    },
    searchStrats(k) {
      if (
          this.acc_strat !== undefined &&
          this.acc_strat !== null &&
          this.acc_strat.length > 0
      ) {
        this.auto_results_strats = [];

        for (var i = 0; i < this.strategies.length; i++) {
          let r = new RegExp(this.acc_strat, "gi");
          if (this.strategies[i].strat_name.match(r) !== null) {
            this.auto_results_strats.push(this.strategies[i]);
          }
        }
        this.show_auto_resultsStrats = this.auto_results_strats.length > 0;
      } else {
        this.show_auto_resultsStrats = false;
      }
    },
    pickedStrat(strat) {
      this.rem_sid = strat;
      const sidx = this.strategies.findIndex(s => parseInt(s.strat_id) === parseInt(strat));
      if (sidx > -1) {
        this.acc_strat = this.strategies[sidx].strat_name;
        this.show_auto_resultsStrats = false;
        this.auto_results_strats = [];
      }
    },
    issponsored(broker) {
      return broker.broker_free_trade_available;
    },
    broker_rule() {
      let success = resp => {
        if(resp.success) {
          this.canFollowStrategyRule()
        } else {
          fail()
        }
      }
      let fail = resp => {
        this.modal_in_progress = false
        swal({
          title: this.$t("attentiontxt"),
          text: this.$t('BROKER_FOLLOW_CONTROL_RULE'),
          type: "warning"
        })
        return false
      }

      this.$validade_broker_rule(this.rem_aid).then(success, fail)
    },
    confirmAddStrat() {
      if (
          this.rem_sid === null ||
          this.rem_sid === "" ||
          this.rem_sid === undefined
      ) {
        this.modal_choose_strat = false;
      } else {
        if (this.agree) {
          this.modal_in_progress = true;
          this.broker_rule()
        } else {
          this.$toast.warning(this.$t("tandaneeded"));
        }
      }
    },
    canFollowStrategyRule(){
      this.$canFollowStrategy(this.rem_sid, this.rem_aid)
        .then((response) => {
          if (response.success) {
            let param = {
              copy_all_trades: this.copy_all_trades
            }
            this.$delsertFollower(this.rem_sid, this.rem_aid, false, param).then(
                this.addedStrat,
                this.failFollowStrategy,
            );
          } else {
            swal({
              title: this.$t("attentiontxt"),
              text: this.$t(response.msg, [response.data]),
              type: "warning",
              showCancelButton: true,
              confirmButtonClass: "btn btn-success",
              cancelButtonClass: "btn btn-danger btn-fill",
              confirmButtonText: this.$t("confirm_and_follow"),
              cancelButtonText: this.$t("cancel"),
            })
                .then(() => {
                  let param = {
                    copy_all_trades: this.copy_all_trades
                  }
                  this.modal_in_progress = true;
                  this.$delsertFollower(
                      this.rem_sid,
                      this.rem_aid,
                      false,
                      param
                  ).then(this.addedStrat, this.failFollowStrategy);
                })
                .catch(swal.noop);
            this.modal_in_progress = false;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$("unkownerror"));
          this.modal_in_progress = false;
          console.log(err);
        });
    },
    confirmDeletionStrat() {
      this.modal_in_progress = true;
      if (this.remM) {
        this.$delStrategy(this.u_eml, this.rem_sid).then(
            this.masterRemoved,
            this.failop,
        );
      } else {
        this.$delsertFollower(this.rem_sid, this.rem_aid, true).then(
            this.followerRemoved,
            this.failop,
        );
      }
    },
    postAddAcc(showModalNotice = true) {
      this.acc_strat = null;
      this.agree = false;
      this.closeModalConfirmStrat(showModalNotice);
      this.is_master = false;
      this.configFollowModal();
      this.modal_notice = showModalNotice;
    },
    addedStrat(resp) {
      if (!resp.success) {
        if (resp.msg) {
          this.$toast.warning(this.$t(resp.msg));
        } else {
          this.$toast.error(this.$t("unkownerror"));
        }
        this.postAddAcc(false);
        return;
      }
      this.$toast.success(this.$t("addedstrat"));
      // Remove Accounts
      localStorage.removeItem("hc_accounts-" + this.u_eml);
      // Reset polling
      clearInterval(this.poll_accs);
      this.poll_accs = null;
      // Re-get
      //this.getAccounts();
      setTimeout(this.postAddAcc, timercountdown);
    },
    followerRemoved(resp) {
      this.$toast.success(this.$t("removedfollower"));
      // Remove Accounts
      localStorage.removeItem("hc_accounts-" + this.u_eml);
      // Reset polling
      clearInterval(this.poll_accs);
      this.poll_accs = null;
      // Re-get
      //this.getAccounts();
      setTimeout(this.closeModalConfirmAlertStrat, timercountdown);
    },
    masterRemoved(resp) {
      this.$toast.success(this.$t("strategyremoved"));
      // Reset polling
      clearInterval(this.poll_accs);
      this.poll_accs = null;
      // Remove Accounts
      localStorage.removeItem("hc_accounts-" + this.u_eml);
      setTimeout(this.closeModalConfirmAlertStrat, timercountdown);
    },
    confirmDeletion() {
      this.modal_in_progress = true;
      this.$delAccount({
        userid: this.u_eml,
        account: this.acc.account,
        platform: !this.acc.platform ? "mt4" : this.acc.platform,
        server: this.acc.server,
      }).then(this.accountDeleted, this.failop);
    },
    accountAdded(resp) {
      if (!resp.success && resp.msg) {
        swal(this.$t("attentiontxt"), this.$t(resp.msg), "warning");
        this.modal_in_progress = false;
      } else {
        this.$toast.success(this.$t("validatingaccount"));
        // Remove Accounts
        localStorage.removeItem("hc_accounts-" + this.u_eml);
        setTimeout(this.closeModal, timercountdown);
      }
    },
    accountDeleted(resp) {
      this.$toast.success(this.$t("deletedaccount"));
      // Remove Accounts
      localStorage.removeItem("hc_accounts-" + this.u_eml);
      setTimeout(this.closeModalConfirmAlert, timercountdown);
    },
    accountUpdated() {
      this.$toast.success(this.$t("validatingupaccount"));
      // Remove Accounts
      localStorage.removeItem("hc_accounts-" + this.u_eml);
      setTimeout(this.closeModal, timercountdown);
    },
    accountDelete(account_number) {
      let accList = this.statsCards.filter(a => a.account_number === account_number);
      if (accList.length === 1) {
        const acc = accList[0];
        this.acc = {
          account: acc.account_number,
          server: acc.account_server,
          password: acc.account_password,
          cents: acc.account_cent,
          demo: acc.account_demo,
          broker: acc.account_broker,
          status: acc.account_reporting_status,
          platform: acc.account_platform,
          unvalidated: acc.unvalidated,
        };
        // Stop polling
        clearInterval(this.poll_accs);
        this.poll_accs = null;
        // Open Modal Confirm
        this.modal_confirm = true;
      }
    },
    accountInfo(account) {
      let aidx = this.statsCards.findIndex(a => a.account_number === account);
      if (aidx >= 0) {
        const acc = this.statsCards[aidx];
        this.acc = {
          account_number: acc.account_number,
          account_server: acc.account_server,
          account_password: acc.account_password,
          account_cent: acc.account_cent,
          account_demo: acc.account_demo,
          account_platform: acc.account_platform,
          account_broker: acc.broker_name,
          account_reporting_status: acc.account_reporting_status,
          account_status: acc.account_status,
          unvalidated: acc.unvalidated,
          account_public: acc.account_public,
          account_nickname: acc.account_nickname,
        };
        this.new_account = false;
      } else {
        this.acc = {
          account_number: null,
          account_server: null,
          account_password: null,
          account_cent: false,
          account_userid: null,
          account_disabled: false,
          account_status: null,
          account_platform: undefined,
          account_nickname: "",
          unvalidated: false,
        };
        this.new_account = true;
      }
      // Stop polling
      clearInterval(this.poll_accs);
      this.poll_accs = null;
      this.other_server = "";
      // Open Modal
      this.modal_update_credentials = true;
    },
    // sendRequest() {
    //   this.modal_in_progress = true;
    //   this.modal_alert = false;
    //   this.sendEmailToBroker(0);
    // },
    cancelModal() {
      this.modal_earn = false;
      this.modal_in_progress = false;
    },
    gotItModal() {
      this.modal_notice = false;
      this.link_modal = false;
    },
    cancelModalAlert() {
      this.strat_param_change = null;
      this.strat_drop_modal = false;
      this.show_follow_strategy_modal = false;
      this.show_remove_follow_strategy_modal = false;
      this.show_aram_change_modal = false;
      this.drop.action = null;
    },
    loadWatchlist(resp, cached) {
      this.ownstrats = [];
      this.ownstrats = resp.data.slice(0);
      for (var i = 0; i < resp.data.length; i++) {
        const w = resp.data[i];
        const sidx = this.strategies.findIndex(
            (s) => s.strat_id === w.strat_id,
        );
        if (sidx < 0) {
          this.strategies.push(w);
        }
      }
      // Cached
      if (cached === undefined || !cached) {
        const allstrats = {
          resp: resp,
          expiry: this.$moment("timezone", "Europe/London", "LLLL ss")
              .add(WLIST_EXPIRY, "m")
              .unix(),
        };
        localStorage.setItem(
            "hc_admin_all_strats_wlist-" + this.u_eml,
            JSON.stringify(allstrats),
        );
      }
      this.featching_strategies = false
      this.$getBrokers().then(this.loadBrokers, this.failop);
      // this.$getServers().then(this.loadBrokers, this.failop);
    },
    loadStrategies(resp, cached) {
      let usid = {};
      this.strategies = [];
      for (var i = 0; i < resp.data.length; i++) {
        const s = resp.data[i];
        if (!(s.strat_id in usid)) {
          usid[s.strat_id] = true;
          this.strategies.push(s);
        }
      }
      if (!cached) {
        const allstrats = {
          resp: resp,
          expiry: this.$moment("timezone", "Europe/London", "LLLL ss")
              .add(STRAT_EXPIRY, "m")
              .unix(),
        };
        localStorage.setItem(
            "hc_admin_all_strats-" + this.u_eml,
            JSON.stringify(allstrats),
        );
      }

      let allstratswlist = localStorage.getItem(
          "hc_admin_all_strats_wlist-" + this.u_eml,
      );
      let existLocal = false;
      const now = this.$moment("timezone", "Europe/London", "LLLL ss").unix();

      if (allstratswlist) {
        allstratswlist = JSON.parse(allstratswlist);
        if (now <= allstratswlist.expiry) {
          this.loadWatchlist(allstratswlist.resp, true);
        }
      }
      if (!existLocal) {
        this.$getWatchlist({eml: this.u_eml}).then(
            this.loadWatchlist,
            this.failop,
        );
      }
    },
    _loadAccounts() {
      this.reorder();

      this.clonedAccounts = this.statsCards.slice(0);

      // Strategy List
      for (let i = 0; i < this.statsCards.length; i++) {
        const _acc = this.statsCards[i];
        if (this.strategyCheck(_acc.strategy_id)) {
          const _s = {
            strategy_id: _acc.strategy_id,
            strategy_name: _acc.strategy_name,
          };

          if (_acc.account_type === "follower") {
            this.show_param_change_btn = true;
            // Add to list of strategy to change
            const sidx = this.strat_param_change_list.findIndex(
                (s) => s.strategy_id === parseInt(_s.strategy_id),
            );
            if (sidx < 0) {
              this.strat_param_change_list.push(_s);
            }
          }
        }
      }

      if (this.search_user) {
        this.search_users();
      } else {
        this.applyFilter(true);
      }


      // Show stuff
      this.in_progress = false;

      if (this.$route.params.newAccount) {
        delete this.$route.params.newAccount;
        this.$nextTick(() => this.accountInfo());
      }
    },
    loadAccounts(resp) {
      if (resp.success) {
        this.loadAccountsRaw(resp);
      } else {
        this.$getAllAccounts_v2({eml: this.u_eml}).then(
            this.loadAccountsRaw,
            this.failop,
        );
      }
    },
    loadAccountsRaw(resp) {
      const now = this.$moment("timezone", "Europe/London", "LLLL ss").unix();

      this.statsCards = [];
      this.clonedAccounts = [];

      for (let i = 0; i < resp.data.validated.length; i++) {
        let verified = resp.data.validated[i];

        // Default circle warning light
        verified["status_type"] = "danger";
        verified["reporting_status"] = false;
        verified['unvalidated'] = false;

        if (verified["info_last_update"]) {
          const delta = now - ["info_last_update"];
          if (delta < 60) {
            verified["info_last_update"] = this.$t("justnow");
          }
          if (delta < 120) {
            verified["info_last_update"] = this.$t("aboutaminuteago");
          } else {
            verified["reference"] = verified["info_last_update"];
            verified["info_last_update"] = this.$moment(
                verified["info_last_update"] * 1000,
            ).format("YYYY-MM-DD HH:mm:ss");
          }
        }

        if (verified["strategy_created_dt"]) {
          verified["reference"] = verified["strategy_created_dt"];
          verified["strategy_created_dt"] = this.$moment(
              verified["strategy_created_dt"] * 1000,
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        if (verified["strategy_followed_dt"] !== null) {
          verified["reference"] = verified["strategy_followed_dt"];
          verified["strategy_followed_dt"] = this.$moment(
              verified["strategy_followed_dt"] * 1000,
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        if (verified["reference"] === undefined) {
          verified["reference"] = "";
        }

        // Unvalidated check
        if (verified['info_last_update'] !== null && verified['account_status'] === 'disconnected') {
          verified['unvalidated'] = true;
        } else {
          // Check Master failed login or nofollower failed login
          verified['unvalidated'] = verified['master_failed_login'] || verified['nofollower_failed_login'];
        }

        verified["showDisconnected"] = verified["master_failed_counter"] > 3 || verified["follower_failed_counter"] > 3

        verified["fixedfee"] = 0;
        verified["fees"] = 0;

        if (verified["strategy_id"]) {
          // Statuses

          if (verified["platform_pending"]) {
            verified["reporting_status"] = this.$t("pendingsetup");
          } else {
            if (verified["change_pending"]) {
              verified['reporting_status'] = this.$t('changespending');

              if (verified["account_status"] !== 'disconnected') {
                verified['status_type'] = 'success';
              }
            } else {
              if (verified["account_status"] !== 'disconnected') {
                verified['status_type'] = 'success';
              }
            }
          }
        }

        if (!verified["account_demo"]) {
          if (this.issponsored(verified)) {
            verified["fixedfee"] = parseFloat(
                this.copy_trading_pricing.sponsored_account,
            );
            if (this.issponsored(verified) || verified["account_premium"]) {
              verified["fees"] = parseFloat(verified["performance_fee"] * 100);
            }
          } else {
            verified["fixedfee"] = parseFloat(
                this.copy_trading_pricing.live_account,
            );
            if (!this.issponsored(verified)) {
              verified["fees"] = parseFloat(verified["performance_fee"] * 100);
            }
          }
        } else {
          verified["fixedfee"] = parseFloat(
              this.copy_trading_pricing.demo_account,
          );
        }

        // Master always shows the performance fee as-is
        if (verified["account_type"] === "master") {
          verified["fees"] = parseFloat(verified["performance_fee"] * 100);
        }

        if (verified["account_type"] === "follower") {
          this.show_param_change_btn = true;
          // Add to list of strategy to change
          const sidx = this.strat_param_change_list.findIndex(
              (s) => s.strategy_id === parseInt(verified["strategy_id"]),
          );

          const _s = {
            strategy_id: verified["strategy_id"],
            strategy_name: verified["strategy_name"],
          };

          if (sidx < 0) {
            this.strat_param_change_list.push(_s);
          }

          if (!verified["account_demo"]) {
            verified["fees"] = parseFloat(verified["performance_fee"] * 100);
            if (this.issponsored(verified) || verified["account_premium"]) {
              verified["fees"] = parseFloat(verified["performance_fee"] * 100);
            }
          }
        }

        if (verified["follower_settings"]) {
          verified["follower_settings"] = verified["follower_settings"];
        } else {
          verified["follower_settings"] = [];
        }

        this.statsCards.push(verified);
      }

      for (let unverified of resp.data.unvalidated) {

        // Default
        unverified["status_type"] = "danger";
        unverified["reporting_status"] = false;

        unverified["unvalidated"] = true;

        this.statsCards.push(unverified);
      }

      // Proceed Load Accounts
      this._loadAccounts();
    },

    loadBrokers(resp) {
      this.broker_list = [];

      for (var j = 0; j < resp.data.length; j++) {
        if (this.broker_list.findIndex(
            (bl) => bl.prop === resp.data[j].broker) < 0
        ) {
          this.broker_list.push({
            broker: resp.data[j].broker,
            platform: resp.data[j].platform,
          });
        }
      }

      if (!rules.isWhitelabelSession || rules.auto_add_brokers) {
        this.broker_list.push({
          broker: "Other",
          platform: "Other",
        });
      }
      // Get Partner Brokers
      this.$getPartnerBrokers_v2({user: true}).then(
          this.loadedPartnerBrokers,
          this.failop,
      );
    },
    loadedPartnerBrokers(resp) {
      this.partner_brokers = resp.data.slice(0);
      // this.getAccounts();
    },
    prevPage() {
      window.history.back();
    },
    formatDateWeird(date) {
      try {
        const dateArray = date.split(" ");
        return dateArray[0];
      } catch (e) {
        return date;
      }
    },
    reorder() {
      if (this.sort_by.column === "strategy") {
        this.statsCards.sort(this.sortByStrategy);
      } else if (this.sort_by.column === "accounts") {
        this.statsCards.sort(this.sortByAccountNumber);
      } else if (this.sort_by.column === "balance") {
        this.statsCards.sort(this.sortByBalance);
      } else if (this.sort_by.column === "equity") {
        this.statsCards.sort(this.sortByEquity);
      } else if (this.sort_by.column === "dt_reference") {
        this.statsCards.sort(this.sortByDateRef);
      }
    },
    sortByDateRef(a, b) {
      const valA = parseInt(a["reference"]);
      const valB = parseInt(b["reference"]);

      if (valA === valB) {
        return 0;
      }
      if (this.sort_by.order === "asc") {
        return valA > valB ? 1 : -1;
      } else {
        return valA > valB ? -1 : 1;
      }
    },
    sortByBalance(a, b) {
      return this.sortByDecimal(a.info_balance, b.info_balance);
    },
    sortByEquity(a, b) {
      return this.sortByDecimal(a.info_equity, b.info_equity);
    },
    sortByDecimal(a, b) {
      const valA = parseFloat(a);
      const valB = parseFloat(b);

      if (valA === valB) {
        return 0;
      }
      if (this.sort_by.order === "asc") {
        return valA > valB ? 1 : -1;
      } else {
        return valA > valB ? -1 : 1;
      }
    },
    extractStrategy(val) {
      if (!this.strategyCheck(val["strategy_id"])) {
        return "";
      }
      return val["strat_name"];
    },
    sortByStrategy(a, b) {
      const valA = this.extractStrategy(a);
      const valB = this.extractStrategy(b);

      if (valA === valB) {
        return 0;
      }
      if (this.sort_by.order === "asc") {
        return valA > valB ? 1 : -1;
      } else {
        return valA > valB ? -1 : 1;
      }
    },
    sortByAccountNumber(a, b) {
      const valA = parseInt(a["account"]);
      const valB = parseInt(b["account"]);

      if (valA === valB) {
        return 0;
      }
      if (this.sort_by.order === "asc") {
        return valA > valB ? 1 : -1;
      } else {
        return valA > valB ? -1 : 1;
      }
    },
    search_users() {
      if (this.in_progress === false) {

        this.statsCards = [];
        let searchStr = "";
        let accounts = this.clonedAccounts.slice(0);

        if (this.search_user !== null && this.search_user.length > 0) {
          searchStr = new RegExp(this.search_user, "gi");
        }

        if (this.search_user !== null && this.search_user.length > 0) {
          this.statsCards = accounts.filter((e => {
            return (
                (e.account_number && e.account_number.toString().match(searchStr)) ||
                (e.account_server && e.account_server.match(searchStr)) ||
                (e.account_nickname && e.account_nickname.match(searchStr)) ||
                (e.strategy_name && e.strategy_name.match(searchStr))
            )
          }));
        } else {
          this.statsCards = accounts;
        }
        this.applyFilter(true);
      }
    },
    validate() {

      let json_data = {
        platform: this.acc.account_platform,
        broker: this.acc.account_broker,
        server: this.acc.account_server,
        centaccount: this.acc.account_cent,
        password: this.acc.account_password,
        nickname: this.acc.account_nickname,
      };

      if (json_data.broker === "Other") {
        json_data.broker = this.acc.other;
        json_data.server = this.acc.other_server
      }

      if (this.newentry || this.acc.unvalidated) {
        json_data["password"] = this.acc.account_password;
      }
      if (!this.newentry) {
        json_data["isPublic"] = this.acc.strategy_public;
      }

      let callBack = this.accountAdded;
      if (!this.new_account) {
        callBack = this.accountUpdated;
      }

      this.$addAccount_v2({
        eml: this.u_eml,
        account: this.acc.account_number,
        newacc: this.new_account,
        json_data: json_data,
      }).then(callBack, this.failop);
    },
    failop(error) {
      if (error.data !== undefined) {
        error = error.data.msg;
      }
      this.in_progress = false;
      this.featching_strategies = false
      this.modal_in_progress = false;
      this.syncstratmodal = false;
      if (typeof error === 'string') {
        this.$toast.error(this.$t(error));
      }
    },
    failFollowStrategy(error) {
      this.modal_in_progress = false;
      let msg = "";
      if (error.data.data !== undefined) {
        msg = this.$t(error.data.msg, [error.data.data.currency])
      } else {
        msg = this.$t(error.data.msg);
      }
      swal({
        title: this.$t("attentiontxt"),
        text: msg,
        type: "error",
      });
    },
    callBackWhitelabelSettings(resp) {
      if (!resp.success) {
        swal(this.$t("attentiontxt"), this.$t("WARN_WL_NOT_FOUND"), "warning");
        return;
      }
      this.can_create_strat =
          !!resp.data.create_strategies || this.admin_usr || this.is_broker;
      this.block_new_brokers = !resp.data.auto_add_brokers;
      if (this.block_new_brokers) {
        let value = "Other";
        this.broker_list = this.broker_list.filter((i) => i.label !== value);
      }
    },
    openFilters() {
      this.show_filters = !this.show_filters;
    },
    loadPricing(resp) {
      this.copy_trading_pricing.live_account = parseFloat(
          resp.data.strategy_pricing.live_account,
      );
      this.copy_trading_pricing.demo_account = parseFloat(
          resp.data.strategy_pricing.demo_account,
      );
      this.copy_trading_pricing.sponsored_account = parseFloat(
          resp.data.strategy_pricing.sponsored_account,
      );
    },
    failPricing(resp) {
      console.log(resp);
    },
    getNet(account) {
      this.$getAccNet(account).then(this.loadedNet, this.failop);
      this.show_net_modal = true;
    },
    loadedNet(resp) {
      this.acc.account_net = resp.data[0]["net_deposit"];
      this.acc.account_net_before = resp.data[0]["net_deposit_before"];
    },
    sendToFixNet() {
      let param = {
        value: this.some_new_value,
      };
      this.$dumbmethodapi(param).then(
          this.addSuccessHandler,
          this.addErrorHandler,
      );
    },
    addSuccessHandler(resp) {
      if (!resp.success) {
        this.addErrorHandler(resp);
        return;
      }
      this.show_net_modal = false;
      this.acc.account_net = null;
      this.acc.account_net_before = null;
      this.some_new_value = null;
      this.$toast.success(this.$t("opnetfixsuccess"));
    },
    addErrorHandler(resp) {
      console.log(resp);
      this.$toast.warning(this.$t("opnetfixfail"));
    },
    forceRevalidationComponent(row) {
      let success = resp => {
        if (!resp.data.can_revalidate) {
          this.$toast.warning(this.$t('already_in_revalidation_queue'))
        } else {
          this.setAccount({
            accountNumber: row.account_number,
            password: row.account_password,
            type: row.account_type == null ? 'follower' : 'master',
            platform: row.account_platform,
            server: row.account_server,
            userid: row.account_userid,
            symbol: row.account_symbol
          })
          this.openForceRevalidationModal()
        }
      }
      let failOp = () => {
        this.$toast.error(this.$('unkownerror'));
      }
      this.$canRevalidate(row.account_number).then(success, failOp)
    },
    strategyCheck(strategy) {
      return strategy !== undefined
    },
    refreshAccounts(isTimeout = true) {
      if (isTimeout) {
        this.in_progress = true;
        this.refreshAccountsBtn = true;
        setTimeout(() => {
          this.refreshAccountsBtn = false;
        }, 30000);
      }
      this.$getAllAccounts_v2({eml: this.u_eml})
          .then(this.loadAccountsRaw, this.failop);
    },
    onRefresh(strategy) {
      if (!parseInt(strategy)) {
        let idx = this.strat_param_change_list.findIndex(
            (s) => parseInt(s.strategy_id) === parseInt(strategy.strategy_id)
        );
        if (idx >= 0) {
          this.strat_param_change_list.splice(idx, 1);
        }
      }
      this.refreshAccounts(false)
    },
    openDrawer() {
      this.showDrawer = true
    },
    hasCustomNickname(stats) {
      if (stats.account_nickname === stats.account_number || !stats.account_nickname) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'modal_notice': function (value) {
      if (!value) {
        this.rem_sid = null;
        this.rem_aid = null;
      }
    },
    'showDeleteMultipleAccountModal': function (value) {
      if (!value) {
        this.strat_param_change = null;
        this.getAccounts();
      }
    },
    'showRemoveAccFromStrategyModal': function (value) {
      if (!value) {
        this.strat_param_change = null;
        this.getAccounts();
      }
    },
  },
  mounted() {
    // Admin User or NOT
    this.admin_usr = rules.isAdmin
    this.is_broker = rules.isBroker

    this.$getPricingInfo().then(this.loadPricing, this.failPricing);

    if (this.is_broker) {
      this.getAccounts();
    } else {
      this.getAccounts();
      this.getStrategies();
    }
    // if (rules.isWhitelabelSession) {
    //   this.show_earn_more_path = false;
    //   this.$getWhitelabelSettings().then(
    //       this.callBackWhitelabelSettings,
    //       this.handler,
    //   );
    //   this.hide_premium = rules.hide_premium;
    //   if (this.hide_premium) {
    //     this.drop.actions = this.drop.actions.filter(
    //         (e) => e.prop !== "earnmoretxt",
    //     );
    //   }
    // }
  },
  created() {
    this.$setTranslatingInProgress();
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress");
  },
  destroyed() {
    clearInterval(this.poll_accs);
  },
  handler() {
  },
};
</script>
<style scoped lang="scss">
@import './src/assets/sass/paper/variables';

hr {
  margin-top: 0;
}

.abit-up {
  position: relative;
  top: -10px;
}

.auto-ul-strategies {
  height: 85px;
}

.auto-ul {
  list-style: none;
  padding: 5px;
  overflow: scroll;
  height: 100px;
  position: absolute;
  background-color: white;
  z-index: 9999;
  border: 1px solid silver;
  width: inherit;
}

.auto-ul li:hover {
  background: silver;
  cursor: pointer;
}

.broker-logo {
  float: left;
  top: 7px;
  position: relative;
  padding: 10px 10px 10px 3px;
}

.broker-logo img {
  border-radius: 50%;
  width: 35px;
  border: 1px solid silver;
}

.card .card-footer .stats .btn-group i {
  color: #fff !important;
}

.card .card-footer {
  padding: 0 15px 15px;
}

.custom-filter-drop {
  width: 425px !important;
}

.dropdown-menu .custom-filter-drop:hover {
  color: #212529 !important;
  background-color: transparent !important;
}

.earn-more {
  top: -10px;
}

.meine-op-btn:hover i {
  color: #51bcda !important;
}

.gear-icon {
  position: relative;
  top: -10px;
  padding-left: 10px;
}

.status-icon {
  position: relative;
  top: 10px;
}

.table {
  margin-bottom: 0;
}

.table-responsive {
  overflow: hidden;
}

.logo-broker {
  margin: 0;
  display: inline-block;
}

.logo-broker img {
  border: solid 1px silver;
  border-radius: 100%;
  max-width: 35px;
  max-height: 35px;
}

#divdetails > .p-badge {
  line-height: unset !important;
}

.table-x {
  td,
  th {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
  }
}

.text-bolder {
  color: white;
  font-weight: bolder !important;
}

small.category {
  font-size: small;
  font-weight: bold;
}

@keyframes cooldown {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

button:disabled {
  .cooldown {
    position: absolute;
    width: 3.10rem;
    z-index: 999;
    margin-top: 12px;
    margin-left: -20px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 5px;
      background: $danger-color;
      animation: cooldown 30s linear;
    }
  }
}
</style>
